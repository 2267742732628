import { useMainStore } from '@js/App/Stores/MainStore'

export const CREATE_SEARCH_PROFILE_AND_ACCOUNT_MODAL_ID = 'create-search-profile-and-account'
export const CREATE_RENTBIRD_PLUS_ACCOUNT_MODAL_ID = 'create-rentbird-plus-account'
export const ONBOARDING_MODAL = 'onboarding'
export const VIDEO_MODAL = 'video'

/**
 * This is a custom function that opens a modal with the given ID.
 * It simply adds the ID to the array of open modals in the Pinia store. The ModalRoot component
 * checks this array to show the appropriate modal.
 * @param modalId {string} The modal ID to open.
 */
export const toggleModal = (modalId) => {
  const mainStore = useMainStore()
  mainStore.toggleModal(modalId)
}

export const openModal = (modalId) => {
  const mainStore = useMainStore()
  mainStore.openModal(modalId)
}

export const closeModal = (modalId) => {
  const mainStore = useMainStore()
  mainStore.closeModal(modalId)
}

export const showVideoInModal = (video) => {
  const mainStore = useMainStore()
  mainStore.videoToShowInModal = video
  mainStore.toggleModal(VIDEO_MODAL)
}

// Here we provide the global $toggleModal, $openModal, and $closeModal function to be used in Vue templates.
export default {
  install: (app, options) => {
    app.config.globalProperties.$toggleModal = (modalId) => {
      return toggleModal(modalId)
    }
    app.config.globalProperties.$openModal = (modalId) => {
      return openModal(modalId)
    }
    app.config.globalProperties.$closeModal = (modalId) => {
      return closeModal(modalId)
    }
  }
}
