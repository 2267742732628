<script setup>
import SetUserAccountSettings from '@js/Components/MyRentbird/Onboarding/OnboardingWizard/StepSearchProfileAlsoSearchFor/SetUserAlsoSearchForAccountSettings.vue'
import Button from '@js/Components/Forms/Button/Button.vue'
import NumberOfMatchesBoxAllSearchProfiles
  from '@js/Components/NumberOfMatchesBox/NumberOfMatchesBoxAllSearchProfiles.vue'
import { ref } from 'vue'
import InfoMessageBox from '@js/Components/InfoMessageBox/InfoMessageBox.vue'
import LightImage from '@images/icons/light.png'

const numberOfMatchesBoxRef = ref(null)

defineEmits(['next'])

const onAccountSettingsUpdated = () => {
  numberOfMatchesBoxRef.value.recalculateAllMatchesPerWeek()
}

</script>
<template>
  <div class="flex flex-col h-full bg-white px-4 lg:px-6">
    <div class="flex-grow lg:min-h-[400px]">
      <h4 class="text-xl lg:text-2xl text-purple-700 font-medium">
        {{ $trans('OnboardingWizard.Step1Title') }}
      </h4>
      <NumberOfMatchesBoxAllSearchProfiles
        ref="numberOfMatchesBoxRef"
        class="mt-[16px]"
      />

      <SetUserAccountSettings
        checkbox-location="right"
        class="mt-[16px]"
        @account-settings-updated="onAccountSettingsUpdated"
      />
    </div>
    <div class="lg:mt-[16px]">
      <InfoMessageBox color="gray" :image="LightImage" class="mb-[8px] lg:mb-[16px]">
        <span v-html="$trans('StepSearchProfileAccountSettings.Hint')" />
      </InfoMessageBox>
      <Button
        :full-width-on-desktop="true"
        :full-width-on-mobile="true"
        icon-right="arrow-right"
        @click="$emit('next')"
      >
        {{ $trans('Common.Next') }}
      </Button>
      <div class="text-sm text-gray-600 text-center mt-[8px]">
        {{ $trans('OnboardingWizard.Disclaimer') }}
      </div>
    </div>
  </div>
</template>
