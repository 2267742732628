<script setup>
import { computed, ref, watch } from 'vue'
import PhoneNumberInput from '@js/Components/Forms/PhoneNumberInput/PhoneNumberInput.vue'
import { useUser } from '@js/App/Api/User/User'
import CustomTransitionExpand from '@js/Components/Transitions/CustomTransitionExpand.vue'
import Select from '@js/Components/Forms/Select/Select.vue'
import { trans } from '@js/App/Plugins/Translations'
import { saveNotificationChannel } from '@js/App/Api/User/NotificationChannel'
import InfoMessageBox from '@js/Components/InfoMessageBox/InfoMessageBox.vue'
import ImageLightBulb from '@images/icons/light.png'
import ButtonAppDownload from '@js/Components/Forms/Button/ButtonAppDownload.vue'
import { isAndroidAppEnabled, isIphoneAppEnabled } from '@js/App/FeatureFlags'

defineProps({
  classLabel: {
    type: String,
    default: ''
  }
})

const { user } = useUser()
const internalNotificationChannel = ref(user.value.notificationChannel)
const internalPhoneNumber = ref(user.value.phone)

const errors = ref({})

watch([internalPhoneNumber, internalNotificationChannel], () => {
  if (!internalPhoneNumber.value && internalNotificationChannel.value === 'whatsapp') {
    emit('update:can-continue', false)
    return
  }

  if (internalNotificationChannel.value === 'email') {
    emit('update:can-continue', true)
  }
})

const internalSaveNotifcationChannel = () => {
  return saveNotificationChannel(errors, internalNotificationChannel)
}
defineExpose({ internalSaveNotifcationChannel })

const emit = defineEmits(['update:can-continue'])

const selectOptions = computed(() => {
  const options = [
    {
      label: trans('Common.Email'),
      value: 'email'
    }

  ]

  if (internalNotificationChannel.value === 'whatsapp' || (
    !isAndroidAppEnabled() || !isIphoneAppEnabled()
  )) {
    options.push({
      label: trans('Common.Whatsapp'),
      value: 'whatsapp'
    })
  }

  options.push({
    label: trans('Common.MobileApp'),
    value: 'mobile'
  })

  return options
})
</script>
<template>
  <div>
    <label :class="`block font-medium text-base mb-2 text-purple-700 ${classLabel}`">
      {{ $trans('NotificationChannelSelect.Label') }}
    </label>
    <Select
      v-model="internalNotificationChannel"
      :options="selectOptions"
    />
    <InfoMessageBox
      v-if="!user.hasMobilePhoneLinked"
      class="mt-[8px]"
      color="blue"
      :image="ImageLightBulb"
    >
      <div v-html="$trans('NotificationChannelSelect.InstallOurAppMessage')" />
    </InfoMessageBox>
    <p class="text-base mt-[8px]">
      {{ $trans('NotificationChannelSelect.DownloadTheApp') }}
    </p>
    <div class="flex space-x-[8px]">
      <ButtonAppDownload
        v-if="isIphoneAppEnabled()"
        :is-android="false"
        class="w-[165px]"
        href="https://apps.apple.com/us/app/rentbird-find-rental-houses/id6468817766"
      />
      <ButtonAppDownload
        v-if="isAndroidAppEnabled()"
        :is-android="true"
        class="w-[165px]"
        href="https://play.google.com/store/apps/details?id=io.rentbird"
      />
    </div>
    <CustomTransitionExpand>
      <PhoneNumberInput
        v-show="internalNotificationChannel === 'whatsapp'"
        v-model="internalPhoneNumber"
        :require-validation="false"
        :error="errors.phone || errors.notification_channel"
        class="mt-[20px]"
        @update:has-changed-phone="newValue => {
          $emit('update:can-continue', !newValue)
        }"
        @update:has-saved-new-phone="newValue => {
          if (newValue) {
            $emit('update:can-continue', true)
          }
        }"
      />
    </CustomTransitionExpand>
  </div>
</template>
