<script setup>
import MyRentbirdLayout from '@js/Components/MyRentbird/MyRentbirdLayout/MyRentbirdLayout.vue'
import MyRentbirdBackButton from '@js/Components/MyRentbird/MyRentbirdBackButton/MyRentbirdBackButton.vue'
import MyRentbirdCard from '@js/Components/MyRentbird/MyRentbirdCard/MyRentbirdCard.vue'
import NumberOfMatchesBoxAllSearchProfiles
  from '@js/Components/NumberOfMatchesBox/NumberOfMatchesBoxAllSearchProfiles.vue'
import SetUserAlsoSearchForAccountSettings
  from '@js/Components/MyRentbird/Onboarding/OnboardingWizard/StepSearchProfileAlsoSearchFor/SetUserAlsoSearchForAccountSettings.vue'
import { ref } from 'vue'
import NotificationChannelSelect from '@js/Components/Forms/NotificationChannelSelect/NotificationChannelSelect.vue'
import ShowHouseDetailPageInput from '@js/Components/Forms/ShowHouseDetailPage/ShowHouseDetailPageInput.vue'
import Button from '@js/Components/Forms/Button/Button.vue'
import SetUserSuitableForAccountSettings
  from '@js/Components/MyRentbird/Onboarding/OnboardingWizard/StepSuitableFor/SetUserSuitableForAccountSettings.vue'

const numberOfMatchesBoxRef = ref(null)
const showHouseDetailPageInputRef = ref(null)
const notificationChannelSelectRef = ref(null)

const showSavedMessage = ref(false)

const onSaveChanges = async () => {
  const success = await notificationChannelSelectRef.value.internalSaveNotifcationChannel()
  if (!success) {
    return
  }

  const isSuccess = await showHouseDetailPageInputRef.value.saveShowHouseDetailPage()
  if (isSuccess) {
    showSavedMessage.value = true
  }
}
</script>
<template>
  <MyRentbirdLayout>
    <MyRentbirdBackButton />

    <MyRentbirdCard
      class="mt-[16px] lg:mt-[24px]"
      :can-expand-on-mobile="false"
    >
      <template #title>
        {{ $trans('PerferencesPage.Title') }}
      </template>

      <NotificationChannelSelect
        ref="notificationChannelSelectRef"
        class="mt-[16px]"
        class-label="lg:text-lg text-purple-900 lg:mb-3"
      />

      <div class="w-full border-b border-[#E0E0E1] mt-[24px] mb-[24px]" />

      <NumberOfMatchesBoxAllSearchProfiles
        ref="numberOfMatchesBoxRef"
        class="mb-4 lg:mb-6"
      />

      <div class="block lg:flex justify-start">
        <div class="lg:mr-8 lg:w-1/2">
          <SetUserAlsoSearchForAccountSettings
            title-location="top"
            checkbox-location="left"
            class-label="lg:text-lg text-purple-900"
            @account-settings-updated="() => {
              numberOfMatchesBoxRef.recalculateAllMatchesPerWeek()
            }"
          />
        </div>
        <div class="mt-4 lg:mt-0 mb-4 lg:mb-0 lg:w-1/2">
          <SetUserSuitableForAccountSettings
            checkbox-location="left"
            class-label="lg:text-lg text-purple-900"
            @account-settings-updated="() => {
              numberOfMatchesBoxRef.recalculateAllMatchesPerWeek()
            }"
          />
        </div>
      </div>

      <div class="w-full border-b border-[#E0E0E1] mt-[24px] mb-[24px]" />

      <div class="lg:text-lg font-medium mb-3">
        {{ $trans('PreferencesPage.HouseDetailPageInputTitle') }}
      </div>

      <ShowHouseDetailPageInput
        ref="showHouseDetailPageInputRef"
        :save-on-value-change="false"
      />

      <Button
        class="mt-[24px] lg:mt-[40px]"
        :icon-right="showSavedMessage ? 'check' : 'save'"
        :full-width-on-mobile="true"
        @click="onSaveChanges"
      >
        <span v-if="showSavedMessage">{{ $trans('Common.Saved') }}</span>
        <span v-else>{{ $trans('Common.Save') }}</span>
      </Button>
    </MyRentbirdCard>
  </MyRentbirdLayout>
</template>
<style lang="scss">
.MyRentbird-PreferencesPage {
  background: rgb(234,234,235);
}
</style>
