<script setup>

import { ref } from 'vue'
import MyRentbirdLayout from '@js/Components/MyRentbird/MyRentbirdLayout/MyRentbirdLayout.vue'
import MyRentbirdBackButton from '@js/Components/MyRentbird/MyRentbirdBackButton/MyRentbirdBackButton.vue'
import MyRentbirdCard from '@js/Components/MyRentbird/MyRentbirdCard/MyRentbirdCard.vue'
import DanielleTextbox from '@js/Components/MyRentbird/Offboarding/PersonTextBox/PersonTextBox.vue'
import Icon from '@js/Components/Icon/Icon.vue'
import { useUser } from '@js/App/Api/User/User'
import TextArea from '@js/Components/Forms/TextArea/TextArea.vue'
import Button from '@js/Components/Forms/Button/Button.vue'
import { useMainStore } from '@js/App/Stores/MainStore'
import { doPostRequest } from '@js/App/Api/Infra/ApiClient'
import { useIsMobile } from '@js/App/Utils/BrowserDetection'
import CustomTransitionExpand from '@js/Components/Transitions/CustomTransitionExpand.vue'

const tipsIsExpanded = ref(false)
const { isMobile } = useIsMobile()

const { user } = useUser()

const internalResponseLetter = ref(user.value.responseLetterTemplate.replaceAll('\\n', '\n'))

const mainStore = useMainStore()
const errors = ref({})
const showSavedMessage = ref(false)
const saveResponseLetter = async () => {
  mainStore.startLoading()
  errors.value = {}
  showSavedMessage.value = false

  try {
    const response = await doPostRequest('/api/response-letter', {
      response_letter: internalResponseLetter.value
    })

    if (response.hasErrors) {
      errors.value = response.errors
      return
    }

    showSavedMessage.value = true
  } finally {
    mainStore.stopLoading()
  }
}
</script>
<template>
  <MyRentbirdLayout>
    <MyRentbirdBackButton />

    <div class="pb-[40px] lg:pb-[80px]">
      <MyRentbirdCard
        class="mt-[16px]"
        :can-expand-on-mobile="false"
      >
        <template #title>
          {{ $trans('ResponseLetterPage.Title') }}
        </template>
        <template #default>
          <p class="mb-[24px]" v-html="$trans('ResponseLetterPage.Subtitle')" />

          <div class="lg:flex lg:flex-row-reverse">
            <DanielleTextbox :light="true" class="mb-[24px] lg:ml-[12px]" max-width-on-desktop="lg:w-[400px]">
              <div
                class="flex justify-between items-center hover:cursor-pointer hover:text-pink-500 lg:hover:cursor-auto lg:hover:text-purple-900 mb-[6px]"
                @click="() => {
                  if (!isMobile) { return }
                  tipsIsExpanded = !tipsIsExpanded
                }"
              >
                <div class="font-medium">
                  💡 Tips van Kim
                </div>
                <Icon
                  v-if="isMobile"
                  name="caret-down"
                  class="transition-all"
                  :class="{
                    'rotate-180': tipsIsExpanded
                  }"
                  icon-width="w-[24px] h-[24px]"
                />
              </div>
              <CustomTransitionExpand>
                <div v-if="tipsIsExpanded || !isMobile" v-html="$trans('ResponseLetterPage.Tips')" />
              </CustomTransitionExpand>
            </DanielleTextbox>

            <div class="flex-grow lg:mr-[12px]">
              <TextArea
                v-model="internalResponseLetter"
                class="!h-auto"
                :error="errors.response_letter"
                :rows="20"
              />

              <Button
                :icon-right="showSavedMessage ? 'check' : 'save'"
                :full-width-on-mobile="true"
                class="mt-[24px]"
                @click="saveResponseLetter"
              >
                {{ $trans(showSavedMessage ? 'Common.Saved' : 'Common.Save') }}
              </Button>
            </div>
          </div>
        </template>
      </MyRentbirdCard>
    </div>
  </MyRentbirdLayout>
</template>
<style>
.MyRentbird-ResponseLetterPage {
  background: rgb(234, 234, 235);
}
</style>
