<script setup>

import { onMounted } from 'vue'
import { markOnboardingStepAsCompleted, STEP_ONBOARDING_TIPS } from '@js/App/Api/Onboarding'
import MyRentbirdLayout from '@js/Components/MyRentbird/MyRentbirdLayout/MyRentbirdLayout.vue'
import MyRentbirdBackButton from '@js/Components/MyRentbird/MyRentbirdBackButton/MyRentbirdBackButton.vue'
import { COUNTRY_US, getCountry, getLocale } from '@js/App/Utils/CountryAndLocale'
import FaqQuestion from '@js/Components/FaqQuestion/FaqQuestion.vue'
import BlogPostCard from '@js/Components/MyRentbird/BlogPostCard/BlogPostCard.vue'

onMounted(() => {
  markOnboardingStepAsCompleted(STEP_ONBOARDING_TIPS)
})

</script>
<template>
  <MyRentbirdLayout>
    <MyRentbirdBackButton />

    <div class="pb-[40px] lg:pb-[80px]">
      <BlogPostCard
        class="mt-[16px]"
        :can-expand-on-mobile="false"
      >
        <template #default>
          <div class="">
            <div class="lg:flex lg:items-center">
              <div class="lg:w-1/2 lg:mr-[12px] flex flex-col">
                <h1 class="text-black font-medium text-2xl lg:text-4xl lg:mb-[24px] mb-[8px]">
                  {{ $trans('OnboardingTipsPage.Title') }}
                </h1>
                <p class="text-xs text-black lg:text-sm" v-html="$trans('OnboardingTipsPage.PublishedAt', { date: getCountry() === COUNTRY_US ? '02-16-2024' : '16-02-2024'})" />
                <div class="flex items-center flex-wrap mt-[24px] mb-[24px] lg:order-first">
                  <div class="bg-purple-500 rounded-full text-white text-sm lg:text-base lg:px-[15px] font-medium px-[10px] py-[4px]">
                    {{ $trans('OnboardingTipsPage.TagSearch') }}
                  </div>
                  <div class="pl-[8px]">
                    {{ $trans('OnboardingTipsPage.MinutesReadingTime', { minutes: 3}) }}
                  </div>
                </div>
              </div>

              <div>
                <iframe
                  id="528365613"
                  class="mt-4 lg:mt-0 max-w-full w-[508px] h-[350px] lg:ml-[12px]"
                  :src="getLocale() === 'en' ? 'https://www.youtube.com/embed/bvkkEK2L_a8?enablejsapi=1&origin=https%3A%2F%2Frentbird.nl' : 'https://www.youtube.com/embed/lfoWdjLl5qY?enablejsapi=1&amp;origin=https%3A%2F%2Frentbird.nl'"
                  title="5 tips om súpersnel een woning te vinden - met rentbird"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen="allowfullscreen"
                />
                <div class="text-center">
                  <a :href="`#transcript-${getLocale()}`" class="text-blue-base hover:cursor-pointer hover:underline">{{ $trans('OnboardingTipsPage.RatherReadThanWatch') }}</a>
                </div>
              </div>
            </div>

            <p class="mt-[24px] lg:mt-[40px]" v-html="$trans('OnboardingTipsPage.Subtitle')" />

            <div v-if="getLocale() === 'en'">
              <section class="dashboard-card">
                <h2>Navigate directly to</h2>
                <ul>
                  <li><a class="text-blue-base hover:cursor-pointer hover:underline" href="#e-mailtemplates">Handy email templates for responding to listings</a></li>
                  <li><a class="text-blue-base hover:cursor-pointer hover:underline" href="#push-meldingen">Instructions to enable push notifications</a></li>
                  <li><a class="text-blue-base hover:cursor-pointer hover:underline" href="#overige">Other useful links</a></li>
                </ul>
              </section>
              <section class="dashboard-card">
                <h2 id="e-mailtemplates">
                  Handy email templates for responding to listings
                </h2>
                <FaqQuestion class="mb-4">
                  <template #title>
                    For singles
                  </template>
                  <div class="faq-question__content">
                    <div class="email-template">
                      Dear landlord,<br>
                      <br>
                      I have been looking for rental housing in [CITY/NEIGHBORHOOD/region] for some time now, and I am interested in the property located at [ADDRESS]. I would love to schedule a viewing for this lovely home!<br>
                      <br>
                      My name is [NAME], and I work as a [PROFESSION]. My gross income is €[INCOME] per month. I am single, I [DON'T/DO, IF YES, WHAT KIND OF] have pets and do not smoke.<br>
                      <br>
                      I am available to view the property on [DAYS/TIMES]. When would be a good time for me to come see it?<br>
                      <br>
                      Oh, and I can move in on [IMMEDIATELY/DATE].<br>
                      <br>
                      You can reach me at [PHONE NUMBER] or [EMAIL ADDRESS].<br>
                      <br>
                      Looking forward to hearing from you soon!<br>
                      <br>
                      Best regards,<br>
                      [NAME]<br>
                    </div>
                  </div>
                </FaqQuestion>

                <FaqQuestion class="mb-4">
                  <template #title>
                    Couples and home sharers
                  </template>
                  <div class="faq-question__content">
                    <div class="email-template">
                      Dear landlord,<br>
                      <br>
                      We have been looking for housing in [CITY/NEIGHBORHOOD/region] for some time now, and we are interested in the property located at [ADDRESS]. We would love to schedule a viewing for this lovely home!<br>
                      <br>
                      My name is [NAME], and I work as a [PROFESSION]. My [PARTNER/ROOMMATE] is [NAME], and they work as a [PROFESSION]. Our gross income is €[INCOME] and €[INCOME] per month, respectively. We [DON'T HAVE / HAVE NUMBER OF]
                      children and [DON'T/DO, IF YES, WHAT KIND OF] have pets. We do not smoke.<br>
                      <br>
                      We are available to view the property on [DAYS/TIMES]. When would be a good time for us to come see it?<br>
                      <br>
                      Oh, and we can move in on [IMMEDIATELY/DATE].<br>
                      <br>
                      You can reach me at [PHONE NUMBER] or [EMAIL ADDRESS].<br>
                      <br>
                      Looking forward to hearing from you soon!<br>
                      <br>
                      Best regards,<br>
                      [NAME]
                    </div>
                  </div>
                </FaqQuestion>
              </section>

              <section class="dashboard-card">
                <h2 id="push-meldingen">
                  Instructions to enable push notifications
                </h2>
                <p>
                  Never miss a housing match again! Enable push notifications on your phone by following the links below for your device and email provider:
                </p>
                <ul>
                  <li><a class="text-blue-base hover:cursor-pointer hover:underline" href="https://support.google.com/mail/answer/1075549?hl=en&amp;co=GENIE.Platform%3DAndroid" target="_blank">Instructions for Gmail on Android</a></li>
                  <li><a class="text-blue-base hover:cursor-pointer hover:underline" href="https://support.google.com/mail/answer/1075549?hl=en&amp;co=GENIE.Platform%3DiOS" target="_blank">Instructions voor Gmail on iPone</a></li>
                  <li>
                    <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://support.microsoft.com/en-us/office/optimize-the-outlook-mobile-app-for-your-ios-or-android-phone-de075b19-b73c-4d8a-841b-459982c7e890" target="_blank">Instructions for Outlook on Android</a>
                    (click on "Change or update Outlook notifications")
                  </li>
                  <li><a class="text-blue-base hover:cursor-pointer hover:underline" href="https://support.microsoft.com/en-us/office/how-can-i-turn-push-notifications-and-sounds-on-or-off-ef8be4f4-85f9-4a90-8c4b-a27f483a0f0a" target="_blank">Instructions for Outlook on iPhone</a></li>
                </ul>
                <h4 class="mt-4">
                  NEW: Push notifications via the Rentbird app
                </h4>
                Receive housing matches via the Rentbird app and respond even faster! You can easily activate push notifications via <a class="text-blue-base hover:cursor-pointer hover:underline" href="/my-rentbird/preferences">My Rentbird.</a>
              </section>
              <section class="dashboard-card">
                <h2 id="overige">
                  Other useful links
                </h2>
                <ul>
                  <li><a class="text-blue-base hover:cursor-pointer hover:underline" href="/mijn-rentbird" target="_blank">Go to your Rentbird account</a></li>
                  <li><a class="text-blue-base hover:cursor-pointer hover:underline" href="/dashboard/match-history" target="_blank">View your matches from the past 7 days</a></li>
                  <li><a class="text-blue-base hover:cursor-pointer hover:underline" href="https://rentbird.nl/en/faq" target="_blank">View frequently asked questions</a></li>
                  <li><a class="text-blue-base hover:cursor-pointer hover:underline" href="https://rentbird.nl/en/support" target="_blank">Contact us here</a></li>
                </ul>
              </section>
              <section class="dashboard-card">
                <h2 id="transcript-en">
                  Transcription video
                </h2>
                <p style="white-space: pre-line;">
                  Hey home hunter, you signed up for Rentbird, that’s awesome! <br>
                  <br>
                  In this video we’ll give you 5 tips to find a new home super quickly using Rentbird!<br>
                  <br>
                  Maybe you’ve been looking for a new place for a while and you’ve noticed that it takes an insane amount of time to keep track of all of those rental websites.<br>
                  <br>
                  Or maybe, just like many other home hunters, you’ve \frustrated because the houses you see online often turn out to already be reserved.<br>
                  <br>
                  Or worse, you replied to a listing only to hear… nothing. Not even a rejection. Just… silence.<br>
                  <br>
                  The problem is, there’s just too much competition.<br>
                  <br>
                  A place that has been listed for only 5 minutes has often already been seen by thousands of people.<br>
                  <br>
                  And the good ones receive hundreds of applicants within the first day.<br>
                  <br>
                  All while a landlord only checks about 10 to 20 applicants.<br>
                  <br>
                  So you’d actually have to stare at your screen day and night, and consistently check multiple rental websites to be early.<br>
                  <br>
                  At Rentbird we figured this could be done in a smarter way.<br>
                  <br>
                  Because lot’s of the time consuming searches can be automated.<br>
                  <br>
                  Rentbird uses search bots to do this. These intelligent bots keep an eye on over 1400 websites, day and night.<br>
                  <br>
                  This way, no property will go unnoticed and you’ll even discover those hidden gems that other home hunters miss.<br>
                  <br>
                  <br>
                  The Rentbird search bots are really good at 2 things.<br>
                  <br>
                  1) Finding almost all rental places in the Netherlands, and <br>
                  2) Sending you the listings that match your wishes, super fast.<br>
                  <br>
                  What Rentbird can’t do, however, is reply to these listings. That is your task.<br>
                  <br>
                  And you gotta keep in mind: speed is everything.<br>
                  <br>
                  Our analytics show that users who reply quickly and proactively are more than 3 times more likely to get a new place.<br>
                  <br>
                  Those successful users open their Rentbird matches quicker and more often. And they also reply instantaneously to the listings they like.<br>
                  <br>
                  In other words: Rentbird really works, but it does require you to be proactive as well.<br>
                  <br>
                  That’s why we’re giving you 5 tips to find a new place to live, super fast, using Rentbird.<br>
                  <br>
                  Here we go!<br>
                  <br>
                  <strong>Tip 1) Draft an email template and get all of your documents ready.</strong><br>
                  <br>
                  In order to reply quickly, you need to be prepared.<br>
                  <br>
                  Write down a neat reply email in which you tell a little bit about yourself and show you are interested in this rental property.<br>
                  <br>
                  You also gotta make sure you’ve got all required documents ready to go. Such as your salary and a landlord notice.<br>
                  <br>
                  Underneath this video you’ll find a ready to go email template and a list of documents that landlords often want to see.<br>
                  <br>
                  <strong>Tip 2) Create accounts on popular rental websites, such as housing associations</strong><br>
                  In every area there are people that rent out places, usually housing associations, who offer private rental sector houses for relatively low rental prices.<br>
                  <br>
                  These parties often work with a website that requires you to log in to be able to reply.<br>
                  <br>
                  To be able to reply quickly it’s useful to already make these accounts. Because when one of these parties offers something new, all you gotta do is log in to reply.<br>
                  <br>
                  Under this video you’ll find the housing associations per region we recommend you already make an account for.<br>
                  <br>
                  <strong>Tip 3) Turn on notifications on your phone</strong><br>
                  Instead of checking your email all day long, you can enable notifications specifically for the emails that Rentbird sends you.<br>
                  <br>
                  This way you’ll get a notification whenever there’s a match.<br>
                  <br>
                  This is really useful, and after you find your new place you can simply turn these notifications off!<br>
                  <br>
                  Under this video we’ll explain how to enable these notifications on several phones and email providers.<br>
                  <br>
                  <br>
                  <strong>Tip 4) Try to reply within 1 to 2 minutes</strong><br>
                  Obviously, all of these preparations are only useful if you actually reply quickly.<br>
                  <br>
                  Do you see a house you like? Copy and paste your email template and send it to them right away!<br>
                  <br>
                  <strong>Tip 5) Have you checked out a place and you like it? Let them know right away!</strong><br>
                  It helps being the first to say you want the place, especially if many others also want the same house.<br>
                  <br>
                  But of course, it is important to make the right decision, so if you’re in doubt, sleep on it for a night.<br>
                  <br>
                  But if you can totally picture yourself living there, be sure to be fast.<br>
                  <br>
                  <br>
                  Well here you have them, the 5 tips that’ll help you to be prepared. These tips significantly increase your odds of getting the rental house you like, so get on it today.<br>
                  <br>
                  We wish you lots of luck on your quest to find your perfect place!
                </p>
              </section>
            </div>
          </div>
        </template>
      </BlogPostCard>
    </div>
  </MyRentbirdLayout>
</template>
<style>
.MyRentbird-OnboardingTipsPage {
  background: rgb(234, 234, 235);
}

.MyRentbird-OnboardingTipsPage h2 {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 4px;

  @media screen and (min-width: 997px) {
    font-size: 30px;
    margin-bottom: 8px;
  }
}

.MyRentbird-OnboardingTipsPage h5 {
  font-size: 16px;
  margin-bottom: 8px;
  margin-top: 12px;

  @media screen and (min-width: 997px) {
    margin-top: 12px;
    font-size: 20px;
    margin-bottom: 8px;
  }
}

.MyRentbird-OnboardingTipsPage p {
  margin-bottom: 12px;
}

.MyRentbird-OnboardingTipsPage p, .MyRentbird-OnboardingTipsPage li {
  font-size: 16px;

  @media screen and (min-width: 997px) {
    font-size: 18px;
  }
}

.MyRentbird-OnboardingTipsPage li {
  margin-bottom: 4px;
}

.MyRentbird-OnboardingTipsPage .popular-rental-agents__price {
  padding-left: 6px;
}

.MyRentbird-OnboardingTipsPage .popular-rental-agents__region {
  padding-left: 6px;
}

.MyRentbird-OnboardingTipsPage .dashboard-card {
  margin-bottom: 24px;

  @media screen and (min-width: 997px) {
    margin-bottom: 40px;
  }
}

</style>
