<script setup>
import InlineCheckboxGroup from '@js/Components/Forms/InlineCheckboxGroup/InlineCheckboxGroup.vue'
import { computed, onMounted, ref, watch } from 'vue'
import { doGetRequest, doPostRequest } from '@js/App/Api/Infra/ApiClient'
import { useMainStore } from '@js/App/Stores/MainStore'
import { trans } from '@js/App/Plugins/Translations'
import { COUNTRY_NL, getCountry } from '@js/App/Utils/CountryAndLocale'

defineProps({
  checkboxLocation: {
    type: String
  },
  classLabel: {
    type: String,
    default: ''
  }
})

const searchProfileAccountSettings = ref(null)
const mainStore = useMainStore()

onMounted(async () => {
  mainStore.startLoading()

  const r = await doGetRequest('/api/user/search-profiles/account-settings')
  const accountSettings = r.json?.account_settings

  searchProfileAccountSettings.value = {}
  searchProfileAccountSettings.value.includeWoningcorporaties = accountSettings.include_woningcorporaties
  searchProfileAccountSettings.value.includePaidWebsites = accountSettings.include_paid_websites
  searchProfileAccountSettings.value.includeSocialeLootwoningen = accountSettings.include_sociale_lootwoningen

  mainStore.stopLoading()
})

const emit = defineEmits(['accountSettingsUpdated'])

const getDebounceKey = () => `${searchProfileAccountSettings.value.includeWoningcorporaties}${searchProfileAccountSettings.value.includePaidWebsites}${searchProfileAccountSettings.value.includeSocialeLootwoningen}`

watch(searchProfileAccountSettings, (newValue, oldValue) => {
  if (oldValue === null) { return }

  const valueNow = getDebounceKey()
  setTimeout(async () => {
    if (valueNow !== getDebounceKey()) {
      return
    }

    await doPostRequest('/api/user/search-profiles/account-settings', {
      include_woningcorporaties: searchProfileAccountSettings.value.includeWoningcorporaties,
      include_paid_websites: searchProfileAccountSettings.value.includePaidWebsites,
      include_sociale_lootwoningen: searchProfileAccountSettings.value.includeSocialeLootwoningen
    })

    emit('accountSettingsUpdated')
  }, 500)
}, { deep: true })

const options = computed(() => {
  if (getCountry() === COUNTRY_NL) {
    return [
      { label: trans('SearchProfileWizard.IncludeWoningcorporaties'), value: 'include_woningcorporaties' },
      {
        label: trans('SearchProfileWizard.IncludePaidWebsites'),
        value: 'include_paid_websites',
        tooltip: trans('SearchProfileWizard.IncludePaidWebsitesTooltip')
      },
      {
        label: trans('SearchProfileWizard.IncludeSocialeLootwoningen'),
        value: 'include_sociale_lootwoningen',
        tooltip: trans('SearchProfileWizard.IncludeSocialeLootwoningenTooltip')
      }]
  } else {
    return [
      {
        label: trans('SearchProfileWizard.IncludePaidWebsites'),
        value: 'include_paid_websites',
        tooltip: trans('SearchProfileWizard.IncludePaidWebsitesTooltip')
      }
    ]
  }
})
</script>
<template>
  <InlineCheckboxGroup
    v-if="searchProfileAccountSettings"
    :initial-is-expanded="true"
    :can-collapse="false"
    :checkbox-location="checkboxLocation"
    :class-label="classLabel"
    :model-value="[
      searchProfileAccountSettings.includeWoningcorporaties ? 'include_woningcorporaties' : null,
      searchProfileAccountSettings.includePaidWebsites ? 'include_paid_websites' : null,
      searchProfileAccountSettings.includeSocialeLootwoningen ? 'include_sociale_lootwoningen' : null,
    ].filter(v => v)"
    :options="options"
    @update:model-value="newValue => {
      searchProfileAccountSettings.includeWoningcorporaties= newValue.includes('include_woningcorporaties')
      searchProfileAccountSettings.includePaidWebsites= newValue.includes('include_paid_websites')
      searchProfileAccountSettings.includeSocialeLootwoningen= newValue.includes('include_sociale_lootwoningen')
    }"
  >
    {{ $trans('SearchProfileWizard.AlsoSearchFor') }}
  </InlineCheckboxGroup>
</template>
