<script setup>
import BirdFly from '@images/bird-fly.gif'
import { onMounted, ref } from 'vue'

const props = defineProps({
  extraSmall: {
    type: Boolean,
    default: false
  },
  small: {
    type: Boolean,
    default: false
  },
  showText: {
    type: Boolean,
    default: true
  }
})

const width = ref(25)
const height = ref(23)

onMounted(() => {
  if (props.extraSmall) {
    width.value = 20
    height.value = 18
  } else if (props.small) {
    width.value = 25
    height.value = 23
  } else {
    width.value = 34
    height.value = 31
  }
})
</script>
<template>
  <div class="flex">
    <div
      v-if="showText"
      class="text-base"
      :class="{
        'text-purple-700': true,
        'pt-[3px] pr-4 lg:font-medium': !small,
        'pr-2 relative top-[-1px]': small
      }"
    >
      {{ $trans('LoadingSpinner.Loading') }}
    </div>
    <div class="relative top-[2px]">
      <img :height="height" :width="width" alt="loading-bird" :src="BirdFly">
    </div>
  </div>
</template>
<style lang="scss">

</style>
