<script setup>
import Button from '@js/Components/Forms/Button/Button.vue'
import Icon from '@js/Components/Icon/Icon.vue'
import DateOfBirthInput from '@js/Components/Forms/DateOfBirthInput/DateOfBirthInput.vue'
import { ref } from 'vue'
import Select from '@js/Components/Forms/Select/Select.vue'
import LanguageSwitcherDropdown from '@js/Components/Forms/LanguageSwitcherDropdown/LanguageSwitcherDropdown.vue'
import NotificationChannelSelect from '@js/Components/Forms/NotificationChannelSelect/NotificationChannelSelect.vue'
import { saveUserPersonalData } from '@js/App/Api/User/UserPersonalData'
import { useUser } from '@js/App/Api/User/User'
import { reloadPropsFromServer } from '@js/App/Api/Infra/ServerData'
import { COUNTRY_US, getCountry } from '@js/App/Utils/CountryAndLocale'

const emit = defineEmits(['next', 'previous'])

const { userPersonalData } = useUser()
const internalPersonalData = ref({ ...userPersonalData.value })

const notificationChannelSelectRef = ref(null)
const errors = ref({})

const onClickNext = async () => {
  errors.value = {}
  const { errors: apiErrors, hasErrors } = await saveUserPersonalData(internalPersonalData.value, [
    'date_of_birth', 'gender'
  ])

  errors.value = apiErrors
  if (hasErrors) { return }

  const isSuccess = await notificationChannelSelectRef.value.internalSaveNotifcationChannel()
  if (!isSuccess) { return }

  reloadPropsFromServer()

  emit('next')
}

const canContinue = ref(true)

</script>
<template>
  <div class="flex flex-col h-full bg-white px-4 lg:px-6">
    <div class="flex-grow lg:min-h-[400px]">
      <h4 class="text-xl lg:text-2xl text-purple-700 font-medium">
        {{ $trans('OnboardingWizard.Step3Title') }}
      </h4>
      <div class="lg:flex lg:mt-[24px]">
        <DateOfBirthInput v-model="internalPersonalData.date_of_birth" :error="errors.date_of_birth" class="mt-[16px] lg:mt-0 lg:pr-[8px] lg:w-1/3">
          <template #label>
            {{ $trans('StepDateOfBirthAndMore.Label') }}
          </template>
        </DateOfBirthInput>

        <Select
          v-model="internalPersonalData.gender"
          :error="errors.gender"
          class="mt-[16px] lg:mt-0 lg:pr-[8px] lg:w-1/3"
          :options="[{
            label: $trans('Common.Man'),
            value: 'man',
          }, {
            label: $trans('Common.Woman'),
            value: 'woman',
          }, {
            label: $trans('Common.Other'),
            value: 'other',
          }]"
        >
          <template #label>
            {{ $trans('Common.Gender') }}
          </template>
        </Select>

        <LanguageSwitcherDropdown
          v-if="getCountry() !== COUNTRY_US"
          class="mt-[16px] lg:w-1/3 lg:mt-0"
        />
      </div>
      <div class="mt-[16px]">
        <NotificationChannelSelect
          ref="notificationChannelSelectRef"
          @update:can-continue="newValue => { canContinue = newValue }"
        />
      </div>
    </div>
    <div class="lg:mt-[16px] mt-[16px]">
      <div class="flex">
        <Button :outline="true" @click="$emit('previous')">
          <Icon name="arrow-left" />
        </Button>
        <Button
          class="flex-grow ml-[8px]"
          :full-width-on-desktop="true"
          :full-width-on-mobile="true"
          :disabled="!canContinue"
          icon-right="arrow-right"
          @click="() => {
            if (!canContinue) { return }
            onClickNext()
          }"
        >
          {{ $trans('Common.Next') }}
        </Button>
      </div>
      <div class="text-sm text-gray-600 text-center mt-[8px]">
        {{ $trans('OnboardingWizard.Disclaimer') }}
      </div>
    </div>
  </div>
</template>
