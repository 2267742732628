<script setup>
import SearchProfilesCard from '@js/Components/MyRentbird/SearchProfilesOverviewCard/SearchProfilesOverviewCard.vue'
import MatchHistoryCard from '@js/Components/MyRentbird/MatchHistoryOverviewCard/MatchHistoryOverviewCard.vue'
import FreeAccountMessage from '@js/Components/MyRentbird/FreeAccountMessage/FreeAccountMessage.vue'
import OnboardingCard from '@js/Components/MyRentbird/Onboarding/OnboardingOverviewCard/OnboardingOverviewCard.vue'
import MyRentbirdLayout from '@js/Components/MyRentbird/MyRentbirdLayout/MyRentbirdLayout.vue'
import ResponseLetterCard from '@js/Components/MyRentbird/ResponseLetterCard/ResponseLetterCard.vue'
import SearchBuddyCard from '@js/Components/MyRentbird/SearchBuddyCard/SearchBuddyCard.vue'
import TipsCard from '@js/Components/MyRentbird/TipsCard/TipsCard.vue'
import AddOurNumberMessage from '@js/Components/MyRentbird/AddOurNumberMessage/AddOurNumberMessage.vue'
import ModalInstallOurApp from '@js/Components/MyRentbird/ModalInstallOurApp/ModalInstallOurApp.vue'
import { isSSR } from '@js/App/Utils/Environment'
import { useUser } from '@js/App/Api/User/User'

const { user } = useUser()

</script>
<template>
  <MyRentbirdLayout>
    <FreeAccountMessage v-if="!user.hasActiveSubscription" class="mb-4 lg:mb-6" />
    <AddOurNumberMessage v-if="user.shouldShowAddOurWhatsappNumberMessage" class="mb-4 lg:mb-6" />
    <ModalInstallOurApp v-if="!isSSR()" />
    <OnboardingCard />

    <div class="flex flex-wrap">
      <div class="w-full lg:w-1/2 lg:pr-3 pb-4 lg:pb-0">
        <MatchHistoryCard class="lg:h-full" />
      </div>
      <div class="w-full lg:w-1/2 lg:pl-3 pb-4 lg:pb-0">
        <SearchProfilesCard class="lg:h-full" />
      </div>
    </div>
    <div class="flex flex-wrap lg:mt-[24px] items-stretch">
      <div class="w-full lg:w-1/3 lg:pr-3 pb-4 lg:pb-0">
        <ResponseLetterCard class="lg:h-full" />
      </div>
      <div class="w-full lg:w-1/3 lg:pl-3 lg:pr-3 pb-4 lg:pb-0">
        <SearchBuddyCard class="lg:h-full" />
      </div>
      <div class="w-full lg:w-1/3 lg:pl-3 pb-4 lg:pb-0">
        <TipsCard class="lg:h-full" />
      </div>
    </div>
  </MyRentbirdLayout>
</template>
<style>
.MyRentbird-HomePage {
  background: rgb(234,234,235);
}
</style>
