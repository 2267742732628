<script setup>
import MyRentbirdCard from '@js/Components/MyRentbird/MyRentbirdCard/MyRentbirdCard.vue'
import ProgressBar from '@js/Components/Forms/ProgressBar/ProgressBar.vue'
import { computed } from 'vue'
import OnboardingStep from '@js/Components/MyRentbird/Onboarding/OnboardingOverviewCard/OnboardingStep.vue'
import { useIsMobile } from '@js/App/Utils/BrowserDetection'
import { useMainStore } from '@js/App/Stores/MainStore'
import { ONBOARDING_MODAL } from '@js/App/Plugins/Modals'
import {
  markOnboardingStepAsCompleted,
  STEP_CREATE_EXTRA_SEARCH_PROFILE, STEP_INSTALL_THE_APP, STEP_ONBOARDING_TIPS,
  STEP_PERSONALISE_RENTBIRD
} from '@js/App/Api/Onboarding'
import { getLocalizedUrl } from '@js/App/Plugins/LocalizedUrls'
import { router } from '@inertiajs/vue3'
import { useUser } from '@js/App/Api/User/User'

const { isMobile } = useIsMobile()

const { user } = useUser()

const openOnboardingModal = () => {
  const mainStore = useMainStore()
  mainStore.toggleModal(ONBOARDING_MODAL)
}
const progress = computed(() => {
  let percentage = 0

  if (user.value.completedOnboardingSteps.includes(STEP_PERSONALISE_RENTBIRD)) {
    percentage += 25
  }
  if (user.value.completedOnboardingSteps.includes(STEP_INSTALL_THE_APP)) {
    percentage += 25
  }
  if (user.value.completedOnboardingSteps.includes(STEP_CREATE_EXTRA_SEARCH_PROFILE)) {
    percentage += 25
  }
  if (user.value.completedOnboardingSteps.includes(STEP_ONBOARDING_TIPS)) {
    percentage += 25
  }

  return percentage
})
</script>
<template>
  <div v-show="progress < 100">
    <MyRentbirdCard
      :can-expand-on-mobile="true"
      :default-is-expanded="true"
      class="mb-[16px] lg:mb-[24px]"
    >
      <template #title>
        {{ $trans('OnboardingCard.Title') }}
      </template>
      <template #title-desktop-right>
        <div class="w-[244px] relative top-[1px]">
          <ProgressBar
            :progress="progress"
            color="green"
            :large-height="true"
            :show-percentage="true"
          />
        </div>
      </template>
      <template #body-that-is-always-shown>
        <ProgressBar
          class="lg:hidden"
          :progress="progress"
          color="green"
          :large-height="true"
          :show-percentage="true"
        />
      </template>
      <template #default>
        <div class="mt-[32px] lg:mt-[12px] lg:flex lg:flex-wrap">
          <div class="lg:w-1/2 lg:pr-[20px]">
            <OnboardingStep :is-completed="user.completedOnboardingSteps.includes(STEP_PERSONALISE_RENTBIRD)" @click="() => openOnboardingModal()">
              <template #title>
                {{ $trans('OnboardingCard.Step1Title') }}
              </template>
              <template #default>
                {{ $trans('OnboardingCard.Step1Content') }}
              </template>
              <template #link>
                {{ $trans('OnboardingCard.Step1Link') }}
              </template>
            </OnboardingStep>

            <OnboardingStep
              v-if="isMobile"
              class="mt-[24px] "
              :border-bottom="isMobile"
              :is-completed="user.completedOnboardingSteps?.includes(STEP_ONBOARDING_TIPS)"
              @click="() => {
                router.visit(getLocalizedUrl('my-rentbird.onboarding-tips'))
              }"
            >
              <template #title>
                {{ $trans('OnboardingCard.Step2Title') }}
              </template>
              <template #default>
                {{ $trans('OnboardingCard.Step2Content') }}
              </template>
              <template #link>
                {{ $trans('OnboardingCard.Step2Link') }}
              </template>
            </OnboardingStep>

            <OnboardingStep
              v-if="!isMobile"
              :is-completed="user.completedOnboardingSteps.includes(STEP_INSTALL_THE_APP)"
              class="mt-[24px] lg:mt-[30px]"
              @click="() => {
                router.visit(getLocalizedUrl('my-rentbird.preferences'))
              }"
            >
              <template #title>
                {{ $trans('OnboardingCard.Step3Title') }}
              </template>
              <template #default>
                {{ $trans('OnboardingCard.Step3Content') }}
              </template>
              <template #link>
                {{ $trans('OnboardingCard.Step3Link') }}
              </template>
            </OnboardingStep>
          </div>

          <div class="lg:w-1/2 lg:pl-[20px]">
            <OnboardingStep
              v-if="isMobile"
              class="mt-[24px] lg:mt-0"
              :is-completed="user.completedOnboardingSteps.includes(STEP_INSTALL_THE_APP)"
              @click="() => {
                router.visit(getLocalizedUrl('my-rentbird.preferences'))
              }"
            >
              <template #title>
                {{ $trans('OnboardingCard.Step3Title') }}
              </template>
              <template #default>
                {{ $trans('OnboardingCard.Step3Content') }}
              </template>
              <template #link>
                {{ $trans('OnboardingCard.Step3Link') }}
              </template>
            </OnboardingStep>

            <OnboardingStep
              v-if="!isMobile"
              class="mt-[24px] lg:mt-0"
              :is-completed="user.completedOnboardingSteps?.includes(STEP_ONBOARDING_TIPS)"
              @click="() => {
                router.visit(getLocalizedUrl('my-rentbird.onboarding-tips'))
              }"
            >
              <template #title>
                {{ $trans('OnboardingCard.Step2Title') }}
              </template>
              <template #default>
                {{ $trans('OnboardingCard.Step2Content') }}
              </template>
              <template #link>
                {{ $trans('OnboardingCard.Step2Link') }}
              </template>
            </OnboardingStep>

            <OnboardingStep
              :is-completed="user.completedOnboardingSteps.includes(STEP_CREATE_EXTRA_SEARCH_PROFILE)"
              class="mt-[24px] lg:mt-[30px]"

              @click="() => { router.visit(getLocalizedUrl('my-rentbird.create-search-profile'))}"
            >
              <template #title>
                {{ $trans('OnboardingCard.Step4Title') }}
              </template>
              <template #default>
                {{ $trans('OnboardingCard.Step4Content') }}
                <a v-if="!user.completedOnboardingSteps.includes(STEP_CREATE_EXTRA_SEARCH_PROFILE)" class="block text-blue-base hover:cursor-pointer hover:underline" @click.prevent="markOnboardingStepAsCompleted(STEP_CREATE_EXTRA_SEARCH_PROFILE)">
                  {{ $trans('OnboardingCard.Step4DontNeedIt') }}
                </a>
              </template>
              <template #link>
                {{ $trans('OnboardingCard.Step4Link') }}
              </template>
            </OnboardingStep>
          </div>
        </div>
      </template>
    </MyRentbirdCard>
  </div>
</template>
