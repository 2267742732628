import { defineStore } from 'pinia'
import { ON_COMPLETE_REDIRECT_TO_UPGRADE_PAGE } from '@js/App/SearchProfile'
import { usePage } from '@inertiajs/vue3'

// Note: this store is manually cleared on an Inertia page load in storesSetup.js
export const useSearchProfileStore = defineStore('searchProfile', {
  state: () => {
    const page = usePage()
    const selectedCity = page.props.selected_city
    const selectedCityHasNeighbourhoods = selectedCity && selectedCity?.neighbourhoods?.length > 0

    return {
      step: 1,
      shouldResetOnNavigation: true,
      searchProfile: {
        serverId: null,

        city: selectedCity
          ? {
              ...selectedCity,
              hasNeighbourhoods: selectedCityHasNeighbourhoods
            }
          : null,
        neighbourhoods: [],
        hoveredNeighbourhood: [],
        locationSelector: selectedCityHasNeighbourhoods ? 'neighbourhoods' : 'distance',
        distance: 10,
        minRent: 0,
        maxRent: 2000,
        minBedrooms: 1,
        minSurface: 0,
        furnished: 'everything',
        hasGarden: false,
        hasBalcony: false,
        hasDakterras: false,
        hasEnergyLabelCOrBetter: false,
        hasBath: false,
        isForPets: false,
        includeWoningcorporaties: true,
        includePaidWebsites: false,
        includeSocialeLootwoningen: false,
        includeRooms: false,
        sharing3OrMore: false,
        isForStudents: false,
        notifyWhenRequirementsNotClear: true,
        sendMoreExpensiveHouses: false,
        houseRequirementsOperator: 'or',

        rentbirdPlusEmail: '',
        rentbirdPlusSearchHomeFrom: 'now',
        rentbirdPlusGoingToLiveWith: 'alone',
        rentbirdPlusCurrentJob: 'loondienst',
        rentbirdPlusIncome: null,
        rentbirdPlusPartnerCurrentJob: 'loondienst',
        rentbirdPlusPartnerIncome: null,
        rentbirdPlusGarantsteller: false,
        rentbirdPlusIHaveMoreThan50K: false,
        rentbirdPlusFirstname: '',
        rentbirdPlusLastname: '',
        rentbirdPlusPhone: '',
        rentbirdPlusCity: '',
        rentbirdPlusCountry: 'NL',
        rentbirdPlusHowToReachMe: ''
      },
      allNeighbourhoodsOfCity: selectedCityHasNeighbourhoods ? selectedCity.neighbourhoods : [],
      actionAfterCompletion: ON_COMPLETE_REDIRECT_TO_UPGRADE_PAGE,
      matchesPerWeek: {
        isLoading: true,
        matchesPerWeek: null
      }
    }
  },

  actions: {
    setSearchProfile (searchProfile) {
      this.searchProfile = searchProfile

      if (this.searchProfile.locationSelector === 'neighbourhoods' && !this.searchProfile.city?.hasNeighbourhoods) {
        this.searchProfile.locationSelector = 'distance'
        this.searchProfile.neighbourhoods = []
      }
    },

    updateSearchProfile (newAttributes) {
      this.setSearchProfile({
        ...this.searchProfile,
        ...newAttributes
      })
    },

    setSearchProfileFromDtoObject (searchProfileDto) {
      this.setSearchProfile({
        ...this.searchProfile,
        ...getSearchProfileFromDto(searchProfileDto)
      })

      if (searchProfileDto.all_neighbourhoods_of_city_without_coordinates) {
        this.allNeighbourhoodsOfCity = searchProfileDto.all_neighbourhoods_of_city_without_coordinates
      }
    }
  }
})

export const getSearchProfileFromDto = (searchProfileDto) => {
  console.dir({ searchProfileDto })
  return {
    serverId: searchProfileDto.random_id,

    city: {
      id: searchProfileDto.city_with_coordinates.id,
      name: searchProfileDto.city_with_coordinates.name,
      hasNeighbourhoods: (searchProfileDto.city_with_coordinates.neighbourhoods || []).length > 0,
      coordinates: searchProfileDto.city_with_coordinates.coordinates
    },
    neighbourhoods: searchProfileDto.neighbourhoods,
    locationSelector: searchProfileDto.location_selector,
    distance: searchProfileDto.distance,
    minRent: searchProfileDto.min_rent,
    maxRent: searchProfileDto.max_rent,
    minBedrooms: searchProfileDto.min_bedrooms,
    minSurface: searchProfileDto.min_surface,
    furnished: searchProfileDto.furnished,
    hasGarden: searchProfileDto.has_garden,
    hasEnergyLabelCOrBetter: searchProfileDto.has_energy_label_c_or_better,
    hasBalcony: searchProfileDto.has_balcony,
    hasDakterras: searchProfileDto.has_dakterras,
    hasBath: searchProfileDto.has_bath,
    isForPets: searchProfileDto.is_for_pets,
    includeWoningcorporaties: searchProfileDto.include_woningcorporaties,
    includePaidWebsites: searchProfileDto.include_paid_websites,
    includeSocialeLootwoningen: searchProfileDto.include_sociale_lootwoningen,
    includeRooms: searchProfileDto.include_rooms,
    sharing3OrMore: searchProfileDto.sharing_3_or_more,
    isForStudents: searchProfileDto.is_for_students,
    notifyWhenRequirementsNotClear: searchProfileDto.notify_when_requirements_not_clear,
    sendMoreExpensiveHouses: searchProfileDto.send_more_expensive_houses,
    houseRequirementsOperator: searchProfileDto.house_requirements_operator
  }
}

export const getSearchProfileDto = searchProfile => ({
  random_id: searchProfile?.serverId,
  city: searchProfile?.city?.id || null,
  neighbourhoods: searchProfile?.neighbourhoods || [],
  location_selector: searchProfile?.locationSelector || 'distance',
  distance: searchProfile?.distance || 10,
  min_rent: searchProfile?.minRent,
  max_rent: searchProfile?.maxRent,
  min_bedrooms: searchProfile?.minBedrooms,
  min_surface: searchProfile?.minSurface,
  furnished: searchProfile?.furnished,
  has_garden: searchProfile?.hasGarden,
  has_energy_label_c_or_better: searchProfile?.hasEnergyLabelCOrBetter,
  has_balcony: searchProfile?.hasBalcony,
  has_dakterras: searchProfile?.hasDakterras,
  has_bath: searchProfile?.hasBath,
  is_for_pets: searchProfile?.isForPets,
  include_woningcorporaties: searchProfile?.includeWoningcorporaties,
  include_paid_websites: searchProfile?.includePaidWebsites,
  include_sociale_lootwoningen: searchProfile?.includeSocialeLootwoningen,
  include_rooms: searchProfile?.includeRooms,
  sharing_3_or_more: searchProfile?.sharing3OrMore,
  is_for_students: searchProfile?.isForStudents,
  notify_when_requirements_not_clear: searchProfile?.notifyWhenRequirementsNotClear,
  send_more_expensive_houses: searchProfile?.sendMoreExpensiveHouses,
  house_requirements_operator: searchProfile?.houseRequirementsOperator
})
