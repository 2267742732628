<script setup>
import { ONBOARDING_MODAL } from '@js/App/Plugins/Modals'
import { ref } from 'vue'
import SmallLabel from '@js/Components/SmallLabel/SmallLabel.vue'
import RentbirdLogo from '@js/Components/Logos/RentbirdLogo/RentbirdLogo.vue'
import Icon from '@js/Components/Icon/Icon.vue'
import WizardWithSlidingSteps from '@js/Components/Forms/WizardWithSlidingSteps/WizardWithSlidingSteps.vue'
import StepSearchProfileAlsoSearchFor
  from '@js/Components/MyRentbird/Onboarding/OnboardingWizard/StepSearchProfileAlsoSearchFor/StepSearchProfileAlsoSearchFor.vue'
import StepDateOfBirthAndMore
  from '@js/Components/MyRentbird/Onboarding/OnboardingWizard/StepDateOfBirthAndMore/StepDateOfBirthAndMore.vue'
import StepLivingRequirements
  from '@js/Components/MyRentbird/Onboarding/OnboardingWizard/StepLivingRequirements/StepLivingRequirements.vue'
import { useMainStore } from '@js/App/Stores/MainStore'
import { trans } from '@js/App/Plugins/Translations'
import { markOnboardingStepAsCompleted, STEP_PERSONALISE_RENTBIRD } from '@js/App/Api/Onboarding'
import StepSuitableFor from '@js/Components/MyRentbird/Onboarding/OnboardingWizard/StepSuitableFor/StepSuitableFor.vue'

const step = ref(1)

const mainStore = useMainStore()
const onFinishedFinalStep = async () => {
  await markOnboardingStepAsCompleted(STEP_PERSONALISE_RENTBIRD)

  mainStore.successMessageText = trans('OnboardingWizard.CompletedMessage')
  mainStore.toggleModal(ONBOARDING_MODAL)
}

</script>
<template>
  <div class="flex-col flex justify-between min-h-full h-full">
    <div class="h-full flex-grow flex flex-col py-4 lg:py-6">
      <div class="flex justify-between items-center px-4 lg:px-6">
        <SmallLabel>
          {{ $trans('SearchProfileWizard.Step', { step: step, total: 4 }) }}
        </SmallLabel>
        <RentbirdLogo :href="false" size="xs" class="pr-12" />
        <Icon name="cross" class="text-purple-700 hover:text-pink-500 transition-colors hover:cursor-pointer" @click="$closeModal(ONBOARDING_MODAL)" />
      </div>

      <WizardWithSlidingSteps :steps="4" :step="step" class="flex flex-col flex-grow pt-[8px]">
        <template #step-1>
          <StepSearchProfileAlsoSearchFor v-if="step === 1" @next="step++" />
        </template>
        <template #step-2>
          <StepSuitableFor v-if="step === 2" @previous="step--" @next="step++" />
        </template>
        <template #step-3>
          <StepDateOfBirthAndMore @previous="step--" @next="step++" />
        </template>
        <template #step-4>
          <StepLivingRequirements @previous="step--" @next="onFinishedFinalStep" />
        </template>
      </WizardWithSlidingSteps>
    </div>
  </div>
</template>
