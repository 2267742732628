<script setup>
import Container from '@js/Components/Container/Container.vue'
import Icon from '@js/Components/Icon/Icon.vue'
import { ref } from 'vue'

const localStorageKey = 'has_hidden_top_bar_'

const hasHiddenTopBar = ref(!!localStorage.getItem(localStorageKey))

const hideTopBar = () => {
  localStorage.setItem(localStorageKey, true)
  hasHiddenTopBar.value = true
}
</script>
<template>
  <div v-if="!hasHiddenTopBar" class="bg-blue-base text-white py-[8px] text-sm font-medium text-center">
    <Container>
      <div class="flex justify-between items-center">
        <div class="w-[48px]">
          &nbsp;
        </div>
        <div
          v-html="$trans('Layout.TopBarText')"
        />

        <Icon
          class="ml-[16px] hover:cursor-pointer hover:text-pink-500 transition-colors"
          name="cross"
          @click="() => {
            hideTopBar()
          }"
        />
      </div>
    </Container>
  </div>
</template>
