import { doPostRequest } from '@js/App/Api/Infra/ApiClient'
import { useMainStore } from '@js/App/Stores/MainStore'
import { reloadPropsFromServer } from '@js/App/Api/Infra/ServerData'

export const STEP_PERSONALISE_RENTBIRD = 'personalise-rentbird'
export const STEP_INSTALL_THE_APP = 'install-the-app'
export const STEP_CREATE_EXTRA_SEARCH_PROFILE = 'create-search-profile'
export const STEP_ONBOARDING_TIPS = 'onboarding-tips'

export const markOnboardingStepAsCompleted = async (step) => {
  const mainStore = useMainStore()
  mainStore.startLoading()
  await doPostRequest('/api/user/complete-onboarding-step', {
    step
  })

  reloadPropsFromServer()

  mainStore.stopLoading()
}
