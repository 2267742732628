<!--THIS IS SHOWN WHEN A USER IS ALREADY LOGGED IN AND ABOUT TO LOGIN INTO THE APP-->
<!--MOBILE USERS WHEN NOT LOGGED IN USE THE /LoginPage.vue COMPONENT-->
<script setup>

import Container from '@js/Components/Container/Container.vue'
import Button from '@js/Components/Forms/Button/Button.vue'
import BasicPageHeader from '@js/Components/Marketing/BasicPageHeader/BasicPageHeader.vue'
import { onBeforeMount } from 'vue'
import { isSSR } from '@js/App/Utils/Environment'

const props = defineProps({ authUrl: String })

onBeforeMount(() => {
  if (props.fromMobile) {
    if (isSSR()) { return }
    window.addEventListener(
      'CookiebotOnDialogInit',
      function (e) {
        if (window.Cookiebot.consent.stamp === 0 && this.top.location !== this.location) {
          window.Cookiebot.submitCustomConsent(!1, !1, !1)
          window.addEventListener(
            'CookiebotOnLoad',
            function (e) {
              window.Cookiebot.deleteConsentCookie()
            },
            false
          )
        }
      },
      false
    )
  }
})
</script>

<template>
  <div class="bg-purple-700 h-screen">
    <BasicPageHeader :show-trustpilot-instead-of-login="true" :show-section-divider="false" />
    <div class="overflow-clip relative">
      <Container class="relative pt-8 text-white">
        <h1 class="text-4xl font-bold leading-snug">
          {{ $trans('MobileLogin.Title.start') }} <span class="p-2 bg-blue-400 rounded-md">{{ $trans('MobileLogin.Title.highlighted') }}</span>
        </h1>
        <p class="my-4 text-base">
          {{ $trans("MobileLogin.Body") }}
        </p>
        <Button
          :big="true"
          :full-width-on-mobile="true"
          :full-width-on-desktop="false"
          icon-right="arrow-right"
          :href="authUrl"
          class="my-4"
          tag="a"
        >
          {{ $trans('MobileLogin.LoginButton') }}
        </Button>
      </Container>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>
