<script setup>
import MyRentbirdBackButton from '@js/Components/MyRentbird/MyRentbirdBackButton/MyRentbirdBackButton.vue'
import MyRentbirdCard from '@js/Components/MyRentbird/MyRentbirdCard/MyRentbirdCard.vue'
import { router } from '@inertiajs/vue3'
import { onMounted, ref } from 'vue'
import SearchProfileCardDistanceOrNeighbourhoodsLabel
  from '@js/Components/SearchProfileCard/SearchProfileCardDistanceOrNeighbourhoodsLabel.vue'
import Button from '@js/Components/Forms/Button/Button.vue'
import { storeToRefs } from 'pinia'
import { getSearchProfileDto, useSearchProfileStore } from '@js/App/Stores/SearchProfileStore'
import CitySelectUsingStore from '@js/Components/Forms/CitySelect/CitySelectUsingStore.vue'
import DistanceSelect from '@js/Components/Forms/DistanceSelect/DistanceSelect.vue'
import NeighbourhoodSelectUsingStore from '@js/Components/Forms/NeighbourhoodSelect/NeighbourhoodSelectUsingStore.vue'
import CityMap from '@js/Components/SearchProfileWizards/SearchProfileWizard/CityMap.vue'
import MaxRentSelect from '@js/Components/Forms/MaxRentSelect/MaxRentSelect.vue'
import MinSurfaceSelect from '@js/Components/Forms/MinSurfaceSelect/MinSurfaceSelect.vue'
import MinBedroomsSelect from '@js/Components/Forms/MinBedroomsSelect/MinBedroomsSelect.vue'
import FurnishedSelect from '@js/Components/Forms/FurnishedSelect/FurnishedSelect.vue'
import SearchProfileStickyBottom
  from '@js/Components/MyRentbird/SearchProfileStickyBottom/SearchProfileStickyBottom.vue'
import { useMatchesPerWeekAndStoreResultsInPinia } from '@js/App/Api/MatchesPerWeek'
import { useMainStore } from '@js/App/Stores/MainStore'
import { deleteSearchProfile, saveSearchProfile } from '@js/App/Api/SearchProfile'
import { useIsMobile } from '@js/App/Utils/BrowserDetection'
import Icon from '@js/Components/Icon/Icon.vue'
import DarkPurpleThinModal from '@js/Components/Modals/DarkPurpleThinModal.vue'
import { getLocalizedUrl } from '@js/App/Plugins/LocalizedUrls'
import NumberOfMatchesBoxUseCurrentSearchProfile
  from '@js/Components/NumberOfMatchesBox/NumberOfMatchesBoxCurrentSearchProfile.vue'
import MyRentbirdLayout from '@js/Components/MyRentbird/MyRentbirdLayout/MyRentbirdLayout.vue'
import { trans } from '@js/App/Plugins/Translations'
import ErrorMessage from '@js/Components/Forms/ErrorMessage/ErrorMessage.vue'
import { markOnboardingStepAsCompleted, STEP_CREATE_EXTRA_SEARCH_PROFILE } from '@js/App/Api/Onboarding'
import CustomTransitionFade from '@js/Components/Transitions/CustomTransitionFade.vue'
import { COUNTRY_US, getCountry } from '@js/App/Utils/CountryAndLocale'
import HouseRequirementsNotClearCheckbox
  from '@js/Components/Forms/EnergyLabelCheckbox/HouseRequirementsNotClearCheckbox.vue'
import LivingRequirementsCheckboxGroup
  from '@js/Components/Forms/LivingRequirementsCheckboxGroup/LivingRequirementsCheckboxGroup.vue'

const searchProfileStore = useSearchProfileStore()
const { searchProfile } = storeToRefs(searchProfileStore)
const mainStore = useMainStore()

const errors = ref({})

useMatchesPerWeekAndStoreResultsInPinia()

const showSuccessfullySavedMessage = ref(false)

const onClickedSave = async () => {
  mainStore.startLoading(false)

  const dto = getSearchProfileDto(searchProfile.value)
  const { errors: apiErrors } = await saveSearchProfile(dto)
  errors.value = apiErrors

  if (Object.keys(errors.value).length <= 0) {
    showSuccessfullySavedMessage.value = true
    mainStore.successMessageText = trans('EditSearchProfilePage.SaveCompleted')
    setTimeout(() => { showSuccessfullySavedMessage.value = false }, 3500)
  }

  mainStore.stopLoading()
}

const onClickedDelete = async () => {
  mainStore.startLoading(false)
  // If no server ID is set, we haven't yet created anything. Just navigate back.
  if (searchProfile.value.serverId) {
    await deleteSearchProfile(searchProfile.value.serverId)
  }
  router.visit(getLocalizedUrl('my-rentbird.home'))
  mainStore.stopLoading()
}

const { isMobile } = useIsMobile()

const confirmDeleteModalIsOpen = ref(false)

onMounted(() => {
  markOnboardingStepAsCompleted(STEP_CREATE_EXTRA_SEARCH_PROFILE)
})

</script>
<template>
  <MyRentbirdLayout>
    <MyRentbirdBackButton />

    <MyRentbirdCard
      class="mt-[16px]"
      :can-expand-on-mobile="false"
    >
      <template #title>
        <div class="flex items-center">
          {{ searchProfile.city?.name || $trans('CreateSearchProfile.YourSearchProfile') }}
          <Icon
            v-tippy="{
              content: searchProfile.serverId ? $trans('Common.Active') : $trans('Common.Inactive'),
              allowHTML: true,
            }"
            class="pl-[8px]"
            :name="searchProfile.serverId ? 'dot-green' : 'dot-red'"
          />
        </div>
      </template>
      <template #title-desktop-right>
        <Button :outline="true" :small="true" icon-left="trash" @click="confirmDeleteModalIsOpen = true">
          <span>{{ $trans('Common.Delete') }}</span>
        </Button>
      </template>
      <template #default>
        <SearchProfileCardDistanceOrNeighbourhoodsLabel
          class="lg:relative lg:top-[-8px] lg:mb-[-20px]"
          :search-profile="searchProfile"
          :big-text-on-desktop="true"
        />

        <div class="flex mt-[8px] lg:hidden">
          <Button :outline="true" :small="true" icon-left="trash" @click="confirmDeleteModalIsOpen = true">
            <span>{{ $trans('Common.Delete') }}</span>
          </Button>
        </div>

        <NumberOfMatchesBoxUseCurrentSearchProfile class="mt-5" />

        <div class="lg:flex">
          <div class="lg:flex-grow">
            <div class=" lg:pr-[24px] lg:flex lg:flex-wrap lg:w-full lg:max-w-full">
              <CitySelectUsingStore
                :disabled="getCountry() === COUNTRY_US"
                :text-input-margin-right="false"
                :error="errors.city"
                class="mt-4 lg:w-1/2 lg:pr-[8px] lg:max-w-[50%]"
                :black="true"
              />

              <NeighbourhoodSelectUsingStore
                v-show="searchProfile.locationSelector === 'neighbourhoods'"
                class=" lg:w-1/2 lg:max-w-[50%] lg:pl-[8px] mt-4"
                :error="errors.neighbourhoods"
                @click:distance="searchProfile.locationSelector = 'distance'"
              />

              <DistanceSelect
                v-if="searchProfile.locationSelector === 'distance'"
                class=" lg:w-1/2 lg:max-w-[50%] lg:pl-[8px] mt-4"
                :error="errors.distance"
                @click:neighbourhoods="searchProfile.locationSelector = 'neighbourhoods'"
              />

              <CityMap v-if="isMobile" class="w-full h-[300px] mt-[8px]" />

              <MaxRentSelect :show-send-more-expensive-houses-checkbox="true" :error="errors.max_rent" class="mt-4 w-full" />

              <MinBedroomsSelect
                :show-include-rooms-checkbox="true"
                :plus-and-minus-buttons="false"
                :error="errors.min_bedrooms"
                class="mt-4 lg:w-1/2 lg:max-w-[50%] lg:pr-[8px]"
              />

              <MinSurfaceSelect
                :plus-and-minus-buttons="false"
                :error="errors.min_surface"
                class="mt-4 lg:w-1/2 lg:max-w-[50%] lg:pl-[8px]"
              />

              <FurnishedSelect class="mt-4 lg:w-full" :error="errors.furnished" />
            </div>
          </div>
          <div>
            <CityMap v-if="!isMobile" class="w-[450px] h-[430px] mt-[20px]" />
          </div>
        </div>

        <div class="mt-4 lg:mt-0 lg:w-1/2 lg:mr-[12px]">
          <LivingRequirementsCheckboxGroup
            :initial-is-expanded="true"
          />

          <HouseRequirementsNotClearCheckbox
            class="mt-4"
            :initial-is-expanded="true"
          />
        </div>
        <ErrorMessage v-if="errors.general" class="hidden lg:block mt-[4px]">
          {{ errors.general }}
        </ErrorMessage>

        <div class="hidden lg:flex lg:items-center lg:mt-4">
          <Button
            :icon-right="showSuccessfullySavedMessage ? 'check' : 'save'"
            :full-width-on-mobile="true"
            @click="onClickedSave"
          >
            <span v-if="!showSuccessfullySavedMessage">{{ $trans('Common.Save') }}</span>
            <span v-else>{{ $trans('Common.Saved') }}</span>
          </Button>

          <div
            class="text-sm text-gray-600 ml-[16px] max-w-[600px]"
            v-html="$trans('EditSearchProfilePage.Disclaimer')"
          />
        </div>
      </template>
    </MyRentbirdCard>

    <div
      class="text-xs text-gray-600 text-center mt-4 pb-[70px] lg:hidden"
      v-html="$trans('EditSearchProfilePage.Disclaimer')"
    />

    <SearchProfileStickyBottom
      class="lg:hidden"
      :show-successfully-saved-message="showSuccessfullySavedMessage"
      @click="onClickedSave"
    >
      <ErrorMessage v-if="errors.general" class="mt-[6px]">
        {{ errors.general }}
      </ErrorMessage>
    </SearchProfileStickyBottom>

    <CustomTransitionFade>
      <DarkPurpleThinModal v-show="confirmDeleteModalIsOpen" :show-top-bar="false">
        <h4 class="text-white text-xl font-medium">
          {{ $trans('EditSearchProfilePage.DeleteTitle') }}
        </h4>
        <p class="mt-[6px] text-base">
          {{ $trans('EditSearchProfilePage.DeleteContent') }}
        </p>
        <Button
          :full-width-on-mobile="true"
          :full-width-on-desktop="true"
          icon-right="trash"
          class="mt-[32px]"
          @click="onClickedDelete"
        >
          <span v-html="$trans('EditSearchProfilePage.DeleteConfirm', { name: searchProfile.city?.name || $trans('CreateSearchProfile.YourSearchProfile') })" />
        </Button>
        <Button
          :full-width-on-mobile="true"
          :full-width-on-desktop="true"
          icon-right="back"
          class="mt-[8px]"
          :outline="true"
          @click="confirmDeleteModalIsOpen = false"
        >
          <span v-html="$trans('EditSearchProfilePage.CancelDelete')" />
        </Button>
      </DarkPurpleThinModal>
    </CustomTransitionFade>
  </MyRentbirdLayout>
</template>
<style>
.MyRentbird-CreateSearchProfilePage {
  background: rgb(234,234,235);
}
</style>
