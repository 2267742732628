import { doGetRequest } from '@js/App/Api/Infra/ApiClient'
import { computed, onMounted, ref } from 'vue'
import { usePage } from '@inertiajs/vue3'

/**
 * Gets the number of missed matches from the users last search profile
 * @returns {{
 *   isLoading: ComputedRef<boolean>,
 *   hasErrors: Ref<UnwrapRef<boolean>>,
 *   missedMatches: Ref<UnwrapRef<number>>,
 *   errors: Ref<UnwrapRef<*[]>>
 * }}
 */
export const useMissedMatchesOfLoggedInUsersLastSearchProfile = () => {
  const numberOfItemsThatAreLoading = ref(0)
  const hasErrors = ref(false)
  const errors = ref([])
  const missedMatches = ref(0)
  const houses = ref([])

  const getMissedMatches = () => {
    numberOfItemsThatAreLoading.value += 1
    setTimeout(async () => {
      const response = await doGetRequest('/api/user/missed-matches-last-30-days', {}, false)

      hasErrors.value = response.hasErrors || false
      errors.value = response.errors || []
      missedMatches.value = response.json?.houses_total
      houses.value = response.json?.houses

      numberOfItemsThatAreLoading.value -= 1
    }, 500)
  }

  const isLoading = computed(() => numberOfItemsThatAreLoading.value > 0)

  onMounted(async () => {
    const page = usePage()
    const missedMatchesFromPageProps = page.props.missedMatches
    if (!missedMatchesFromPageProps) {
      houses.value = null
      await getMissedMatches()
    } else {
      houses.value = missedMatchesFromPageProps.houses
      missedMatches.value = missedMatchesFromPageProps.houses_total
    }
  })

  return {
    isLoading, hasErrors, errors, missedMatches, houses
  }
}
