<script setup>
import LoadingSpinner from '@js/Components/Forms/LoadingSpinner/LoadingSpinner.vue'
import { useMainStore } from '@js/App/Stores/MainStore'
import { ref, watch } from 'vue'
import { storeToRefs } from 'pinia'

const DELAY_BEFORE_SHOWING_SPINNER_IN_MS = 500
const MIN_TIME_LOADING_SPINNER_IN_SCREEN_IN_MS = 300

const mainStore = useMainStore()
const showLoadingSpinner = ref(false)

const { numberOfItemsThatAreLoading, showLoadingSpinnerInstantly } = storeToRefs(mainStore)

let startShowLoadingSpinnerAt = null

watch(numberOfItemsThatAreLoading, () => {
  if (numberOfItemsThatAreLoading.value <= 0) {
    hideOverlay()
    return
  }

  showOverlay()
})

const hideOverlay = () => {
  const timeSpinnerIsInScreen = performance.now() - startShowLoadingSpinnerAt
  const waitTime = Math.max(1, MIN_TIME_LOADING_SPINNER_IN_SCREEN_IN_MS - timeSpinnerIsInScreen)

  // Don't instantly close the loading spinner. To avoid a flashing effect
  setTimeout(
    () => { showLoadingSpinner.value = false },
    waitTime
  )
}

const showOverlay = () => {
  setTimeout(() => {
    if (numberOfItemsThatAreLoading.value > 0 && !showLoadingSpinner.value) {
      showLoadingSpinner.value = true
      startShowLoadingSpinnerAt = performance.now()
    }
  }, showLoadingSpinnerInstantly.value ? 1 : DELAY_BEFORE_SHOWING_SPINNER_IN_MS)
}

</script>
<template>
  <Transition>
    <div
      v-show="showLoadingSpinner"
      class="fixed inset-0 bg-white bg-opacity-50 flex justify-center items-center z-90 transition-all duration-500"
    >
      <LoadingSpinner
        :show-text="true"
        :big="true"
      />
    </div>
  </Transition>
</template>
