<script setup>
import { ref } from 'vue'
import Button from '@js/Components/Forms/Button/Button.vue'
import Icon from '@js/Components/Icon/Icon.vue'
import { saveUserPersonalData } from '@js/App/Api/User/UserPersonalData'
import LivingRequirementsInput from '@js/Components/Forms/LivingRequirementsInput/LivingRequirementsInput.vue'
import { useUser } from '@js/App/Api/User/User'
import { reloadPropsFromServer } from '@js/App/Api/Infra/ServerData'

const { userPersonalData } = useUser()
const internalPersonalData = ref({ ...userPersonalData.value })

const emit = defineEmits(['previous', 'next'])

const errors = ref({})

const onClickNext = async () => {
  errors.value = {}

  const { errors: apiErrors, hasErrors } = await saveUserPersonalData(internalPersonalData.value, [
    'current_job', 'is_going_to_live_with', 'total_monthly_income', 'reason_for_moving'
  ])
  errors.value = apiErrors

  if (hasErrors) { return }
  reloadPropsFromServer()
  emit('next')
}
</script>
<template>
  <div class="flex flex-col h-full bg-white px-4 lg:px-6">
    <div class="flex-grow lg:min-h-[400px]">
      <h4 class="text-xl mb-[16px] lg:text-2xl text-purple-700 font-medium">
        {{ $trans('OnboardingWizard.StepLivingRequirementsTitle') }}
      </h4>

      <LivingRequirementsInput v-if="internalPersonalData" v-model="internalPersonalData" :errors="errors" />
    </div>
    <div class="lg:mt-[16px] mt-[16px]">
      <div class="flex">
        <Button :outline="true" @click="$emit('previous')">
          <Icon name="arrow-left" />
        </Button>
        <Button
          class="flex-grow ml-[8px]"
          :full-width-on-desktop="true"
          :full-width-on-mobile="true"
          icon-right="check"
          @click="onClickNext"
        >
          {{ $trans('StepLivingRequirements.ButtonLabel') }}
        </Button>
      </div>
      <div class="text-sm text-gray-600 text-center mt-[8px]">
        {{ $trans('OnboardingWizard.Disclaimer') }}
      </div>
    </div>
  </div>
</template>
