<script setup>
import Button from '@js/Components/Forms/Button/Button.vue'
import NumberOfMatchesBoxAllSearchProfiles
  from '@js/Components/NumberOfMatchesBox/NumberOfMatchesBoxAllSearchProfiles.vue'
import { ref } from 'vue'
import InfoMessageBox from '@js/Components/InfoMessageBox/InfoMessageBox.vue'
import LightImage from '@images/icons/light.png'
import SetUserSuitableForAccountSettings
  from '@js/Components/MyRentbird/Onboarding/OnboardingWizard/StepSuitableFor/SetUserSuitableForAccountSettings.vue'
import Icon from '@js/Components/Icon/Icon.vue'

const numberOfMatchesBoxRef = ref(null)
const canContinue = ref(true)

defineEmits(['next', 'previous'])

const onAccountSettingsUpdated = () => {
  numberOfMatchesBoxRef.value.recalculateAllMatchesPerWeek()
}
</script>
<template>
  <div class="flex flex-col h-full bg-white px-4 lg:px-6">
    <div class="flex-grow lg:min-h-[400px]">
      <h4 class="text-xl lg:text-2xl text-purple-700 font-medium">
        {{ $trans('OnboardingWizard.Step1Title') }}
      </h4>
      <NumberOfMatchesBoxAllSearchProfiles
        ref="numberOfMatchesBoxRef"
        class="mt-[16px]"
      />

      <SetUserSuitableForAccountSettings
        checkbox-location="right"
        class="mt-[16px]"
        @account-settings-updated="onAccountSettingsUpdated"
      />
    </div>
    <div class="lg:mt-[16px]">
      <InfoMessageBox color="gray" :image="LightImage" class="mb-[8px] lg:mb-[16px]">
        <span v-html="$trans('StepSearchProfileAccountSettings.Hint')" />
      </InfoMessageBox>
      <div class="flex">
        <Button :outline="true" @click="$emit('previous')">
          <Icon name="arrow-left" />
        </Button>
        <Button
          class="flex-grow ml-[8px]"
          :full-width-on-desktop="true"
          :full-width-on-mobile="true"
          :disabled="!canContinue"
          icon-right="arrow-right"
          @click="$emit('next')"
        >
          {{ $trans('Common.Next') }}
        </Button>
      </div>

      <div class="text-sm text-gray-600 text-center mt-[8px]">
        {{ $trans('OnboardingWizard.Disclaimer') }}
      </div>
    </div>
  </div>
</template>
