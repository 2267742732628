<script setup>
import DanielleTextbox from '@js/Components/MyRentbird/Offboarding/PersonTextBox/PersonTextBox.vue'
import Button from '@js/Components/Forms/Button/Button.vue'
import { ref } from 'vue'
import { usePage } from '@inertiajs/vue3'

const page = usePage()
const trustpilotInviteLink = page.props.trustpilot_invite_link

const hasClickedTrustpilotButton = ref(false)

const onClickTrustpilotButton = () => {
  hasClickedTrustpilotButton.value = true
  window.open(trustpilotInviteLink, '_blank').focus()
}

defineEmits(['clicked:next'])
</script>
<template>
  <div>
    <DanielleTextbox>
      <template #title>
        {{ $trans('ReviewUsPage.Title') }}
      </template>
      <span v-html="$trans('ReviewUsPage.Text')" />
    </DanielleTextbox>

    <div class="lg:w-[600px]">
      <Button
        class="mt-[32px]"
        :full-width-on-mobile="true"
        :full-width-on-desktop="true"
        :big="true"
        :outline="true"
        icon-right="external"
        @click="onClickTrustpilotButton"
      >
        {{ $trans('ReviewUsTrustpilotStep.ButtonLabel') }}
      </Button>

      <Button
        class="mt-[8px]"
        :full-width-on-mobile="true"
        :full-width-on-desktop="true"
        :big="true"
        icon-right="check"
        :disabled="!hasClickedTrustpilotButton"
        @click="() => {
          if (!hasClickedTrustpilotButton) { return }
          $emit('clicked:next')
        }"
      >
        {{ $trans('ReviewUsTrustpilotStep.IPlacedTheReview') }}
      </Button>
    </div>
  </div>
</template>
