<script setup>
import ImageAndroidDownload from '@images/button-android-download.svg'
import ImageIosDownload from '@images/button-ios-download.svg'

import ImageAndroidDownloadBlack from '@images/button-android-download-black.svg'
import ImageIosDownloadBlack from '@images/button-ios-download-black.svg'

import Image from '@js/Components/Image/Image.vue'

function getImage (isAndroid, isBlack) {
  if (isAndroid) {
    if (isBlack) {
      return ImageAndroidDownloadBlack
    } else {
      return ImageAndroidDownload
    }
  }

  if (isBlack) {
    return ImageIosDownloadBlack
  } else {
    return ImageIosDownload
  }
}

defineProps({
  isAndroid: {
    type: Boolean,
    default: false
  },
  href: {
    type: String,
    default: '#'
  },
  target: {
    type: String,
    default: '#'
  },
  isBlack: {
    type: Boolean,
    default: false
  }
})
</script>
<template>
  <a
    :href="href"
    class="hover:cursor-pointer group"
    :target="target"
  >
    <Image
      class="w-[137px] lg:w-[205px] group-hover:opacity-90 transition-all "
      :src="getImage(isAndroid, isBlack)"
    />
  </a>
</template>
