<script setup>
import Icon from '@js/Components/Icon/Icon.vue'
import SearchProfileCardLabel from '@js/Components/SearchProfileCard/SearchProfileCardLabel.vue'
import SearchProfileCardDistanceOrNeighbourhoodsLabel
  from '@js/Components/SearchProfileCard/SearchProfileCardDistanceOrNeighbourhoodsLabel.vue'
import { Link } from '@inertiajs/vue3'
import { COUNTRY_US, getCountry, getCurrency } from '@js/App/Utils/CountryAndLocale'
import { computed } from 'vue'
import { getLocalizedUrl } from '@js/App/Plugins/LocalizedUrls'
import { convertM2ToSquareFeet } from '../../App/Utils/MetricSystem'

const props = defineProps({
  searchProfile: {
    type: Object,
    default: () => ({})
  },
  showEditButton: {
    type: Boolean,
    default: false
  },
  roundedBottom: {
    type: Boolean,
    default: false
  },
  editUrl: {
    type: String,
    default: '#'
  }
})

const searchProfileEditUrl = computed(() => {
  if (!props.showEditButton) {
    return false
  }
  if (props.editUrl && props.editUrl !== '#') {
    return props.editUrl
  }

  return getLocalizedUrl('my-rentbird.edit-search-profile', { searchProfileRandomId: props.searchProfile.serverId })
})
</script>
<template>
  <component
    :is="showEditButton ? Link : 'div'"
    class="bg-gray-50 px-[16px] py-[16px] rounded-t-md block transition-colors"
    :class="{
      'group hover:cursor-pointer hover:bg-gray-100': showEditButton,
      'rounded-b-md': roundedBottom,
    }"
    :href="searchProfileEditUrl"
  >
    <div class="flex justify-between items-center">
      <div class="text-purple-900 font-medium text-xl mb-[4px]">
        {{ searchProfile.city?.name || searchProfile.city_formatted }}
      </div>
      <div
        v-show="showEditButton"
      >
        <Icon
          name="edit"
          icon-width="w-[20px] h-[20px]"
          class="group-hover:text-pink-500 transition-colors"
          @click="() => console.log('TODO do something here')"
        />
      </div>
    </div>
    <SearchProfileCardDistanceOrNeighbourhoodsLabel :search-profile="searchProfile" />

    <div class="flex flex-wrap">
      <SearchProfileCardLabel class="mr-[4px] mt-[4px]">
        <div class="flex">
          <Icon name="bed-alternative" icon-width="w-[24px] h-[24px]" class="mr-[4px]" />
          <span class="text-base text-purple-900">≥ {{ searchProfile.minBedrooms || searchProfile.min_bedrooms }}</span>
        </div>
      </SearchProfileCardLabel>

      <SearchProfileCardLabel class="mr-[4px] mt-[4px]">
        <div class="flex">
          <Icon name="ruler" icon-width="w-[24px] h-[24px]" class="mr-[4px]" />
          <span class="text-base text-purple-900">≥
            <span v-if="getCountry() === COUNTRY_US">
              {{ convertM2ToSquareFeet(searchProfile.minSurface || searchProfile.min_surface) }}sqft
            </span>
            <span v-else>
              {{ searchProfile.minSurface || searchProfile.min_surface }}m<sup>2</sup>
            </span>
          </span>
        </div>
      </SearchProfileCardLabel>

      <SearchProfileCardLabel class="mr-[0px] mt-[4px]">
        <div class="flex">
          <Icon name="tag" icon-width="w-[24px] h-[24px]" class="mr-[4px]" />
          <span class="text-base text-purple-900">{{ getCurrency() }}{{ searchProfile.minRent || searchProfile.min_rent }} - {{ getCurrency() }}{{ searchProfile.maxRent || searchProfile.max_rent }}</span>
        </div>
      </SearchProfileCardLabel>
    </div>
  </component>
</template>
