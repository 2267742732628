<script setup>

import { onMounted } from 'vue'
import { markOnboardingStepAsCompleted, STEP_ONBOARDING_TIPS } from '@js/App/Api/Onboarding'
import MyRentbirdLayout from '@js/Components/MyRentbird/MyRentbirdLayout/MyRentbirdLayout.vue'
import MyRentbirdBackButton from '@js/Components/MyRentbird/MyRentbirdBackButton/MyRentbirdBackButton.vue'
import { COUNTRY_US, getCountry, getLocale } from '@js/App/Utils/CountryAndLocale'
import FaqQuestion from '@js/Components/FaqQuestion/FaqQuestion.vue'
import BlogPostCard from '@js/Components/MyRentbird/BlogPostCard/BlogPostCard.vue'

onMounted(() => {
  markOnboardingStepAsCompleted(STEP_ONBOARDING_TIPS)
})

</script>
<template>
  <MyRentbirdLayout>
    <MyRentbirdBackButton />

    <div class="pb-[40px] lg:pb-[80px]">
      <BlogPostCard
        class="mt-[16px]"
        :can-expand-on-mobile="false"
      >
        <template #default>
          <div class="">
            <div class="lg:flex lg:items-center">
              <div class="lg:w-1/2 lg:mr-[12px] flex flex-col">
                <h1 class="text-black font-medium text-2xl lg:text-4xl lg:mb-[24px] mb-[8px]">
                  {{ $trans('OnboardingTipsPage.Title') }}
                </h1>
                <p class="text-xs text-black lg:text-sm" v-html="$trans('OnboardingTipsPage.PublishedAt', { date: getCountry() === COUNTRY_US ? '02-16-2024' : '16-02-2024'})" />
                <div class="flex items-center flex-wrap mt-[24px] mb-[24px] lg:order-first">
                  <div class="bg-purple-500 rounded-full text-white text-sm lg:text-base lg:px-[15px] font-medium px-[10px] py-[4px]">
                    {{ $trans('OnboardingTipsPage.TagSearch') }}
                  </div>
                  <div class="pl-[8px]">
                    {{ $trans('OnboardingTipsPage.MinutesReadingTime', { minutes: 3}) }}
                  </div>
                </div>
              </div>

              <div>
                <iframe
                  id="528365613"
                  class="mt-4 lg:mt-0 max-w-full w-[508px] h-[350px] lg:ml-[12px]"
                  :src="getLocale() === 'en' ? 'https://www.youtube.com/embed/bvkkEK2L_a8?enablejsapi=1&origin=https%3A%2F%2Frentbird.nl' : 'https://www.youtube.com/embed/lfoWdjLl5qY?enablejsapi=1&amp;origin=https%3A%2F%2Frentbird.nl'"
                  title="5 tips om súpersnel een woning te vinden - met rentbird"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen="allowfullscreen"
                />
                <div class="text-center">
                  <a :href="`#transcript-${getLocale()}`" class="text-blue-base hover:cursor-pointer hover:underline">{{ $trans('OnboardingTipsPage.RatherReadThanWatch') }}</a>
                </div>
              </div>
            </div>

            <p class="mt-[24px] lg:mt-[40px]" v-html="$trans('OnboardingTipsPage.Subtitle')" />

            <div v-if="getLocale() === 'nl'">
              <section class="dashboard-card mb-4 mt-4">
                <h2>Navigeer direct naar</h2>
                <ul>
                  <li><a class="text-blue-base hover:cursor-pointer hover:underline" href="#e-mailtemplates">Handige e-mailtemplates om te reageren op woningen</a> (tip 1)</li>
                  <li><a class="text-blue-base hover:cursor-pointer hover:underline" href="#benodigde-documenten">Benodigde documenten om te kunnen huren</a> (tip 2)</li>
                  <li><a class="text-blue-base hover:cursor-pointer hover:underline" href="#populaire-verhuurders">Populaire verhuurders per regio waar je alvast kunt registreren</a> (tip 3)</li>
                  <li><a class="text-blue-base hover:cursor-pointer hover:underline" href="#push-meldingen">Instructies om push-meldingen aan te zetten</a> (tip 4)</li>
                  <li><a class="text-blue-base hover:cursor-pointer hover:underline" href="#overige">Overige handige links</a> (tip 5)</li>
                </ul>
              </section>
              <section class="dashboard-card">
                <h2 id="e-mailtemplates">
                  Handige e-mailtemplates om te reageren op woningen
                </h2>

                <FaqQuestion
                  class="mb-4"
                >
                  <template #title>
                    Voor alleenstaanden
                  </template>
                  <template #default>
                    <div class="faq-question__content">
                      <div class="email-template">
                        Beste verhuurder,<br>
                        <br>
                        Al enige tijd ben ik op zoek naar woonruimte in [STAD / BUURT / REGIO] en daarbij is mijn oog gevallen op de woning op het adres [ADRES]. Ik zou deze leuke woning erg graag willen bezichtigen!<br>
                        <br>
                        Mijn naam is [NAAM] en ik ben [BEROEP]. Mijn bruto inkomen is €[INKOMEN] per maand. Ik ben alleenstaand, heb [GEEN / WEL, ZO JA WELKE] huisdieren en rook niet.<br>
                        <br>
                        Ik ben beschikbaar op [DAGEN / TIJDEN] om te bezichtigen. Wanneer zou ik langs kunnen komen om de woning te kijken?<br>
                        <br>
                        Oh ja, ik kan per [DIRECT / DATUM] verhuizen.<br>
                        <br>
                        U kunt mij bereiken via [TELNUMMER] of [MAILADRES].<br>
                        <br>
                        Ik hoop snel van u te horen!<br>
                        <br>
                        Met vriendelijke groet,<br>
                        <br>
                        [NAAM]
                      </div>
                    </div>
                  </template>
                </FaqQuestion>

                <FaqQuestion
                  class="mb-4"
                >
                  <template #title>
                    Voor stellen of delers
                  </template>
                  <template #default>
                    <div class="faq-question__content">
                      <div class="email-template">
                        Beste verhuurder,<br>
                        <br>
                        Al enige tijd zijn wij op zoek naar woonruimte in [STAD / BUURT / REGIO] en daarbij is ons oog gevallen op de woning op het adres [ADRES]. We zouden deze leuke woning erg graag willen bezichtigen!<br>
                        <br>
                        Mijn naam is [NAAM] en ik ben [BEROEP]. Mijn [PARTNER / HUISGENOOT] heet [NAAM] en is [BEROEP]. Ons bruto inkomen is respectievelijk €[INKOMEN] en €[INKOMEN] per maand. We hebben [GEEN / AANTAL] kinderen en [GEEN / WEL,
                        ZO JA WELKE] huisdieren. We roken niet.<br>
                        <br>
                        We zijn beschikbaar op [DAGEN / TIJDEN] om te bezichtigen. Wanneer zouden we langs kunnen komen om de woning te kijken?<br>
                        <br>
                        Oh ja, wij kunnen per [DIRECT / DATUM] verhuizen.<br>
                        <br>
                        U kunt mij bereiken via [TELNUMMER] of [MAILADRES].<br>
                        <br>
                        We hopen snel van u te horen!<br>
                        <br>
                        Met vriendelijke groet,<br>
                        [NAAM]
                      </div>
                    </div>
                  </template>
                </FaqQuestion>
              </section>
              <section class="dashboard-card">
                <h2 id="benodigde-documenten">
                  Benodigde documenten om te kunnen huren
                </h2>
                <FaqQuestion
                  class="mb-4"
                >
                  <template #title>
                    Als je in loondienst werkt
                  </template>
                  <template #default>
                    <div class="faq-question__content">
                      <ul>
                        <li>Kopie paspoort</li>
                        <li>Recent ondertekende werkgeversverklaring (en evt. arbeidsovereenkomst)</li>
                        <li>3 meest recente loonstroken</li>
                        <li>Recent bankafschrift met zichtbare bijschrijving salaris conform loonstrook</li>
                        <li>Uittreksel GBA (Gemeentelijke Basisadministratie)</li>
                      </ul>
                      <h5>Indien je al een huurwoning bewoont</h5>
                      <ul>
                        <li>Recent ondertekende verhuurdersverklaring</li>
                        <li>Bankafschriften met zichtbare afschrijvingen van laatste 3 maand huren</li>
                      </ul>
                    </div>
                  </template>
                </FaqQuestion>

                <FaqQuestion
                  class="mb-4"
                >
                  <template #title>
                    Als je zelfstandig ondernemer bent
                  </template>
                  <template #default>
                    <div class="faq-question__content">
                      <ul>
                        <li>Kopie paspoort</li>
                        <li>Recent uittreksel KvK</li>
                        <li>Winst en verliesrekening laatste 3 jaar</li>
                        <li>IB60 formulier laatste 3 jaar</li>
                        <li>Uittreksel GBA (Gemeentelijke Basisadministratie)</li>
                      </ul>
                      <h5>Indien je al een huurwoning bewoont</h5>
                      <ul>
                        <li>Recent ondertekende verhuurdersverklaring</li>
                        <li>Bankafschriften met zichtbare afschrijvingen van laatste 3 maand huren</li>
                      </ul>
                    </div>
                  </template>
                </FaqQuestion>
              </section>
              <section class="dashboard-card">
                <h2 id="populaire-verhuurders">
                  Populaire verhuurders per regio waar je alvast kunt registreren
                </h2>
                <p>Meer kans maken op woningen van populaire verhuurders in jouw regio? Maak dan alvast een account aan, zodat je direct kunt reageren op nieuwe woningen van deze verhuurders!</p>

                <FaqQuestion
                  class="mb-4"
                >
                  <template #title>
                    Provincie Noord-Holland
                  </template>
                  <template #default>
                    <div class="faq-question__content">
                      <ul class="popular-rental-agents__list">
                        <li class="popular-rental-agents__rental-agent">
                          <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://www.ymere.nl/" target="_blank">Ymere</a><span class="popular-rental-agents__price">(gratis)</span><span class="popular-rental-agents__region">regio Amsterdam / Amstelveen / Zaandam</span>
                        </li>
                        <li class="popular-rental-agents__rental-agent">
                          <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://www.eigenhaard.nl/" target="_blank">Eigen Haard</a><span class="popular-rental-agents__price">(gratis)</span><span class="popular-rental-agents__region">regio Amsterdam / Amstelveen / Zaandam</span>
                        </li>
                        <li class="popular-rental-agents__rental-agent">
                          <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://www.vesteda.com/" target="_blank">Vesteda</a><span class="popular-rental-agents__price">(gratis)</span><span class="popular-rental-agents__region">regio Amsterdam / Amstelveen / Zaandam</span>
                        </li>
                        <li class="popular-rental-agents__rental-agent">
                          <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://benopzoek.nl/" target="_blank">Ben Op Zoek</a><span class="popular-rental-agents__price">(gratis)</span>
                          <span class="popular-rental-agents__region">regio Amsterdam / Amstelveen / Zaandam / Hilversum</span>
                        </li>
                        <li class="popular-rental-agents__rental-agent">
                          <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://ikwilhuren.nu/" target="_blank">Ikwilhuren.nu</a><span class="popular-rental-agents__price">(€35 inschrijfkosten)</span>
                        </li>
                        <li class="popular-rental-agents__rental-agent">
                          <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://www.vindjeplekbijdekey.nl/" target="_blank">De Key</a><span class="popular-rental-agents__price">(gratis)</span><span class="popular-rental-agents__region">Amsterdam</span>
                        </li>
                        <li class="popular-rental-agents__rental-agent">
                          <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://www.eigenhaard.nl/" target="_blank">Eigen Haard</a><span class="popular-rental-agents__price">(gratis)</span><span class="popular-rental-agents__region">regio Amsterdam / Amstelveen / Zaandam</span>
                        </li>
                        <a
                          href="
                        https://www.woningnetregioamsterdam.nl/"
                          target="_blank"
                        >
                          Woningnet regio Amsterdam
                        </a>
                        <span class="popular-rental-agents__price">(€50 inschrijfkosten)</span>
                        <li class="popular-rental-agents__rental-agent">
                          <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://www.rooftrack.nl/" target="_blank">Rooftrack</a><span class="popular-rental-agents__price">(gratis)</span>
                          <span class="popular-rental-agents__region">regio Amsterdam / Amstelveen / Zaandam / Haarlem / Heemstede</span>
                        </li>
                        <li class="popular-rental-agents__rental-agent">
                          <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://www.svnk.nl/" target="_blank">SVNK</a><span class="popular-rental-agents__price">(€25 inschrijfkosten)</span><span class="popular-rental-agents__region">regio Alkmaar / Noord-Kennemerland</span>
                        </li>
                        <li class="popular-rental-agents__rental-agent">
                          <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://www.mijnwoonservice.nl/" target="_blank">MijnWoonservice</a><span class="popular-rental-agents__price">(€10 inschrijfkosten)</span>
                          <span class="popular-rental-agents__region">Haarlem / Zuid-Kennemerland</span>
                        </li>
                        <li class="popular-rental-agents__rental-agent">
                          <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://www.woningnetgooienvechtstreek.nl/" target="_blank">Woningnet Gooi en Vechtstreek</a><span class="popular-rental-agents__price">(€15 inschrijfkosten)</span>
                        </li>
                        <li class="popular-rental-agents__rental-agent">
                          <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://woonmatchkopnh.nl/" target="_blank">Woonmatch Kop van Noord Holland</a><span class="popular-rental-agents__price">(€15 inschrijfkosten)</span>
                          <span class="popular-rental-agents__region">Den Helder / Schagen / Hollands Kroon</span>
                        </li>
                        <li class="popular-rental-agents__rental-agent">
                          <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://www.wonenindekop.nl/" target="_blank">Woontij</a><span class="popular-rental-agents__price">(€35 inschrijfkosten)</span>
                          <span class="popular-rental-agents__region">Den Helder / Julianadorp / Texel</span>
                        </li>
                        <li class="popular-rental-agents__rental-agent">
                          <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://woonmatchwaterland.nl/" target="_blank">Woonmatch Waterland</a><span class="popular-rental-agents__price">(gratis)</span>
                          <span class="popular-rental-agents__region">Purmerend / Waterland / Edam-Volendam / Beemster</span>
                        </li>
                      </ul>
                    </div>
                  </template>
                </FaqQuestion>

                <FaqQuestion
                  class="mb-4"
                >
                  <template #title>
                    Provincie Drenthe
                  </template>
                  <template #default>
                    <div class="faq-question__content">
                      <ul class="popular-rental-agents__list">
                        <li class="popular-rental-agents__rental-agent">
                          <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://www.thuiskompas.nl/" target="_blank">Thuiskompas</a><span class="popular-rental-agents__price">(gratis)</span>
                        </li>
                        <li class="popular-rental-agents__rental-agent">
                          <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://ikwilhuren.nu/" target="_blank">Ikwilhuren.nu</a><span class="popular-rental-agents__price">(€35 inschrijfkosten)</span>
                        </li>
                      </ul>
                    </div>
                  </template>
                </FaqQuestion>

                <FaqQuestion
                  class="mb-4"
                >
                  <template #title>
                    Provincie Flevoland
                  </template>
                  <template #default>
                    <div class="faq-question__content">
                      <ul class="popular-rental-agents__list">
                        <li class="popular-rental-agents__rental-agent">
                          <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://www.woningnetalmere.nl/" target="_blank">Woningnet Almere</a><span class="popular-rental-agents__price">(€20 inschrijfkosten)</span>
                        </li>
                        <li class="popular-rental-agents__rental-agent">
                          <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://ikwilhuren.nu/" target="_blank">Ikwilhuren.nu</a><span class="popular-rental-agents__price">(€35 inschrijfkosten)</span>
                        </li>
                        <li class="popular-rental-agents__rental-agent">
                          <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://benopzoek.nl/" target="_blank">Ben Op Zoek</a><span class="popular-rental-agents__price">(gratis)</span><span class="popular-rental-agents__region">Almere</span>
                        </li>
                      </ul>
                    </div>
                  </template>
                </FaqQuestion>

                <FaqQuestion
                  class="mb-4"
                >
                  <template #title>
                    Provincie Friesland
                  </template>
                  <template #default>
                    <div class="faq-question__content">
                      <ul class="popular-rental-agents__list">
                        <li class="popular-rental-agents__rental-agent">
                          <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://www.frieslandhuurt.nl/" target="_blank">Friesland Huurt</a><span class="popular-rental-agents__price">(gratis)</span>
                        </li>
                        <li class="popular-rental-agents__rental-agent">
                          <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://woonmatchwestfriesland.nl/" target="_blank">Woonmatch West Friesland</a><span class="popular-rental-agents__price">(gratis)</span>
                        </li>
                        <li class="popular-rental-agents__rental-agent">
                          <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://www.debouwvereniging.nl/" target="_blank">De Bouwvereniging</a><span class="popular-rental-agents__price">(€15 inschrijfkosten)</span><span class="popular-rental-agents__region">Harlingen</span>
                        </li>
                        <li class="popular-rental-agents__rental-agent">
                          <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://ikwilhuren.nu/" target="_blank">Ikwilhuren.nu</a><span class="popular-rental-agents__price">(€35 inschrijfkosten)</span>
                        </li>
                      </ul>
                    </div>
                  </template>
                </FaqQuestion>

                <FaqQuestion
                  class="mb-4"
                >
                  <template #title>
                    Provincie Gelderland
                  </template>
                  <template #default>
                    <div class="faq-question__content">
                      <ul class="popular-rental-agents__list">
                        <li class="popular-rental-agents__rental-agent">
                          <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://www.entree.nu/" target="_blank">Entree</a><span class="popular-rental-agents__price">(gratis)</span><span class="popular-rental-agents__region">regio Arnhem / Nijmegen</span>
                        </li>
                        <li class="popular-rental-agents__rental-agent">
                          <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://www.woonkeus-stedendriehoek.nl/" target="_blank">Woonkeus Stedendriehoek</a><span class="popular-rental-agents__price">(€30 inschrijfkosten)</span>
                          <span class="popular-rental-agents__region">regio Apeldoorn / Deventer / Zutphen</span>
                        </li>
                        <li class="popular-rental-agents__rental-agent">
                          <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://www.thuisindeachterhoek.nl/" target="_blank">Thuis in de Achterhoek</a><span class="popular-rental-agents__price">(gratis)</span><span class="popular-rental-agents__region">regio Achterhoek</span>
                        </li>
                        <li class="popular-rental-agents__rental-agent">
                          <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://ikwilhuren.nu/" target="_blank">Ikwilhuren.nu</a><span class="popular-rental-agents__price">(€35 inschrijfkosten)</span>
                        </li>
                        <li class="popular-rental-agents__rental-agent">
                          <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://www.hurennoordveluwe.nl/" target="_blank">Huren Noord Veluwe</a><span class="popular-rental-agents__price">(€15 inschrijfkosten)</span>
                        </li>
                        <li class="popular-rental-agents__rental-agent">
                          <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://www.mijnwoningzoeken.nl/" target="_blank">Mooiland</a><span class="popular-rental-agents__price">(gratis)</span><span class="popular-rental-agents__region">regio Arnhem / Nijmegen</span>
                        </li>
                      </ul>
                    </div>
                  </template>
                </FaqQuestion>

                <FaqQuestion
                  class="mb-4"
                >
                  <template #title>
                    Provincie Groningen
                  </template>
                  <template #default>
                    <div class="faq-question__content">
                      <ul class="popular-rental-agents__list">
                        <li class="popular-rental-agents__rental-agent">
                          <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://www.rooftrack.nl/" target="_blank">Rooftrack</a><span class="popular-rental-agents__price">(gratis)</span>
                        </li>
                        <li class="popular-rental-agents__rental-agent">
                          <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://woningzoeken.acantus.nl/" target="_blank">Acantus</a><span class="popular-rental-agents__price">(€15 inschrijfkosten)</span>
                        </li>
                        <li class="popular-rental-agents__rental-agent">
                          <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://ikwilhuren.nu/" target="_blank">Ikwilhuren.nu</a><span class="popular-rental-agents__price">(€35 inschrijfkosten)</span>
                        </li>
                      </ul>
                    </div>
                  </template>
                </FaqQuestion>

                <FaqQuestion
                  class="mb-4"
                >
                  <template #title>
                    Provincie Noord-Brabant
                  </template>
                  <template #default>
                    <div class="faq-question__content">
                      <ul class="popular-rental-agents__list">
                        <li class="popular-rental-agents__rental-agent">
                          <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://www.woonserviceregionaal.nl/" target="_blank">WoonService Regionaal</a><span class="popular-rental-agents__price">(€10 inschrijfkosten)</span>
                          <span class="popular-rental-agents__region">Noordoost-Braband</span>
                        </li>
                        <li class="popular-rental-agents__rental-agent">
                          <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://www.zuidwestwonen.nl/" target="_blank">Zuidwestwonen</a><span class="popular-rental-agents__price">(€17,50 inschrijfkosten)</span><span class="popular-rental-agents__region">West-Brabant</span>
                        </li>
                        <li class="popular-rental-agents__rental-agent">
                          <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://www.klikvoorwonen.nl/" target="_blank">Klik voor Wonen</a><span class="popular-rental-agents__price">(€17,50 inschrijfkosten)</span><span class="popular-rental-agents__region">West-Brabant</span>
                        </li>
                        <li class="popular-rental-agents__rental-agent">
                          <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://ikwilhuren.nu/" target="_blank">Ikwilhuren.nu</a><span class="popular-rental-agents__price">(€35 inschrijfkosten)</span>
                        </li>
                        <li class="popular-rental-agents__rental-agent">
                          <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://www.wooniezie.nl/" target="_blank">Wooniezie</a><span class="popular-rental-agents__price">(gratis)</span><span class="popular-rental-agents__region">regio Helmond / Eindhoven</span>
                        </li>
                        <li class="popular-rental-agents__rental-agent">
                          <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://www.onshuiz.nl/" target="_blank">Ons Huiz</a><span class="popular-rental-agents__price">(gratis)</span>
                          <span class="popular-rental-agents__region">regio Uden, Meierijstad en Sint-Michielsgestel</span>
                        </li>
                        <li class="popular-rental-agents__rental-agent">
                          <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://www.woninginzicht.nl/" target="_blank">Woninginzicht</a><span class="popular-rental-agents__price">(€8 inschrijfkosten)</span><span class="popular-rental-agents__region">Midden-Brabant</span>
                        </li>
                        <li class="popular-rental-agents__rental-agent">
                          <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://www.mijnwoningzoeken.nl/" target="_blank">Mooiland</a><span class="popular-rental-agents__price">(gratis)</span><span class="popular-rental-agents__region">Noordoost-Braband</span>
                        </li>
                      </ul>
                    </div>
                  </template>
                </FaqQuestion>

                <FaqQuestion
                  class="mb-4"
                >
                  <template #title>
                    Provincie Overijssel
                  </template>
                  <template #default>
                    <div class="faq-question__content">
                      <ul class="popular-rental-agents__list">
                        <li class="popular-rental-agents__rental-agent">
                          <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://www.woonkeus-stedendriehoek.nl/" target="_blank">Woonkeus Stedendriehoek</a><span class="popular-rental-agents__price">(€30 inschrijfkosten)</span>
                          <span class="popular-rental-agents__region">regio Apeldoorn / Deventer / Zutphen</span>
                        </li>
                        <li class="popular-rental-agents__rental-agent">
                          <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://www.dewoonplaats.nl/" target="_blank">De Woonplaats</a><span class="popular-rental-agents__price">(gratis)</span>
                          <span class="popular-rental-agents__region">Enschede / Aalten / Oost Gelre / Winterswijk</span>
                        </li>
                        <li class="popular-rental-agents__rental-agent">
                          <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://www.dewoningzoeker.nl/" target="_blank">De Woningzoeker</a><span class="popular-rental-agents__price">(€8 inschrijfkosten)</span>
                          <span class="popular-rental-agents__region">regio Zwolle / Kampen / Steenwijkerland / Zwartewaterland</span>
                        </li>
                        <li class="popular-rental-agents__rental-agent">
                          <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://ikwilhuren.nu/" target="_blank">Ikwilhuren.nu</a><span class="popular-rental-agents__price">(€35 inschrijfkosten)</span>
                        </li>
                        <li class="popular-rental-agents__rental-agent">
                          <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://www.woningnethengeloborne.nl/" target="_blank">Woningnet Hengelo Borne</a><span class="popular-rental-agents__price">(€15 inschrijfkosten)</span>
                        </li>
                        <li class="popular-rental-agents__rental-agent">
                          <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://www.thuistreffervechtdal.nl/" target="_blank">Thuistreffer Vechtdal</a><span class="popular-rental-agents__price">(gratis)</span>
                        </li>
                        <li class="popular-rental-agents__rental-agent">
                          <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://www.woonburoalmelo.nl/" target="_blank">Woonburo Almelo</a><span class="popular-rental-agents__price">(€25 inschrijfkosten)</span>
                        </li>
                        <li class="popular-rental-agents__rental-agent">
                          <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://www.mijnwoningzoeken.nl/" target="_blank">Mooiland</a><span class="popular-rental-agents__price">(gratis)</span><span class="popular-rental-agents__region">regio Apeldoorn</span>
                        </li>
                        <li class="popular-rental-agents__rental-agent">
                          <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://woningzoeken.sallandwonen.nl/" target="_blank">SallandWonen</a><span class="popular-rental-agents__price">(€10 inschrijfkosten)</span><span class="popular-rental-agents__region">regio Salland</span>
                        </li>
                        <li class="popular-rental-agents__rental-agent">
                          <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://woningzoeken.reggewoon.nl/" target="_blank">Reggewoon</a><span class="popular-rental-agents__price">(€15 inschrijfkosten)</span>
                          <span class="popular-rental-agents__region">Wierden / Hellendoorn</span>
                        </li>
                      </ul>
                    </div>
                  </template>
                </FaqQuestion>

                <FaqQuestion
                  class="mb-4"
                >
                  <template #title>
                    Provincie Utrecht
                  </template>
                  <template #default>
                    <div class="faq-question__content">
                      <ul class="popular-rental-agents__list">
                        <li class="popular-rental-agents__rental-agent">
                          <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://www.woningnetregioutrecht.nl/" target="_blank">Woningnet regio Utrecht</a><span class="popular-rental-agents__price">(€20 inschrijfkosten)</span>
                        </li>
                        <li class="popular-rental-agents__rental-agent">
                          <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://www.woningnetgooienvechtstreek.nl/" target="_blank">Woningnet Gooi en Vechtstreek</a><span class="popular-rental-agents__price">(€15 inschrijfkosten)</span>
                        </li>
                        <li class="popular-rental-agents__rental-agent">
                          <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://www.woningneteemvallei.nl/" target="_blank">Woningnet regio Eemvallei</a><span class="popular-rental-agents__price">(€17,50 inschrijfkosten)</span>
                        </li>
                        <li class="popular-rental-agents__rental-agent">
                          <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://ikwilhuren.nu/" target="_blank">Ikwilhuren.nu</a><span class="popular-rental-agents__price">(€35 inschrijfkosten)</span>
                        </li>
                        <li class="popular-rental-agents__rental-agent">
                          <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://www.rooftrack.nl/" target="_blank">Rooftrack</a><span class="popular-rental-agents__price">(gratis)</span><span class="popular-rental-agents__region">regio Utrecht</span>
                        </li>
                        <li class="popular-rental-agents__rental-agent">
                          <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://benopzoek.nl/" target="_blank">Ben Op Zoek</a><span class="popular-rental-agents__price">(gratis)</span><span class="popular-rental-agents__region">regio Amersfoort</span>
                        </li>
                        <li class="popular-rental-agents__rental-agent">
                          <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://www.huiswaarts.nu/" target="_blank">Huiswaarts.nu</a><span class="popular-rental-agents__price">(€25 inschrijfkosten)</span>
                          <span class="popular-rental-agents__region">regio Veenendaal / Rhenen / Ede</span>
                        </li>
                      </ul>
                    </div>
                  </template>
                </FaqQuestion>

                <FaqQuestion
                  class="mb-4"
                >
                  <template #title>
                    Provincie Zeeland
                  </template>
                  <template #default>
                    <div class="faq-question__content">
                      <ul class="popular-rental-agents__list">
                        <li class="popular-rental-agents__rental-agent">
                          <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://www.zuidwestwonen.nl/" target="_blank">Zuidwestwonen</a><span class="popular-rental-agents__price">(€17,50 inschrijfkosten)</span>
                        </li>
                        <li class="popular-rental-agents__rental-agent">
                          <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://ikwilhuren.nu/" target="_blank">Ikwilhuren.nu</a><span class="popular-rental-agents__price">(€35 inschrijfkosten)</span>
                        </li>
                      </ul>
                    </div>
                  </template>
                </FaqQuestion>

                <FaqQuestion
                  class="mb-4"
                >
                  <template #title>
                    Provincie Zuid-Holland
                  </template>
                  <template #default>
                    <div class="faq-question__content">
                      <ul class="popular-rental-agents__list">
                        <li class="popular-rental-agents__rental-agent">
                          <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://www.hureninhollandrijnland.nl/" target="_blank">Huren in Holland Rijnland</a><span class="popular-rental-agents__price">(€7,50 inschrijfkosten)</span>
                          <span class="popular-rental-agents__region">regio Leiden / Bollenstreek / Rijnland</span>
                        </li>
                        <li class="popular-rental-agents__rental-agent">
                          <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://www.woonnetrijnmond.nl/" target="_blank">Woonnet Rijnmond</a><span class="popular-rental-agents__price">(€10 inschrijfkosten)</span><span class="popular-rental-agents__region">regio Rotterdam</span>
                        </li>
                        <li class="popular-rental-agents__rental-agent">
                          <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://ikwilhuren.nu/" target="_blank">Ikwilhuren.nu</a><span class="popular-rental-agents__price">(€35 inschrijfkosten)</span>
                        </li>
                        <li class="popular-rental-agents__rental-agent">
                          <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://www.woningnetregiomiddenholland.nl/" target="_blank">Woingnet regio Midden-Holland</a><span class="popular-rental-agents__price">(€23 inschrijfkosten)</span>
                        </li>
                        <li class="popular-rental-agents__rental-agent">
                          <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://www.woonnet-haaglanden.nl/" target="_blank">Woonnet Haaglanden</a><span class="popular-rental-agents__price">(€12,50 inschrijfkosten)</span>
                          <span class="popular-rental-agents__region">regio Den Haag</span>
                        </li>
                        <li class="popular-rental-agents__rental-agent">
                          <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://www.woonstadrotterdam.nl/" target="_blank">Woonstad Rotterdam</a><span class="popular-rental-agents__price">(gratis)</span>
                        </li>
                        <li class="popular-rental-agents__rental-agent">
                          <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://www.wonenindekrimpenerwaard.nl/" target="_blank">Wonen in de Krimpenerwaard</a><span class="popular-rental-agents__price">(gratis)</span>
                        </li>
                        <li class="popular-rental-agents__rental-agent">
                          <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://www.thuisbijhwwonen.nl/" target="_blank">HW Wonen</a><span class="popular-rental-agents__price">(gratis)</span><span class="popular-rental-agents__region">Hoekse Waard</span>
                        </li>
                        <li class="popular-rental-agents__rental-agent">
                          <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://www.oostwestwonen.nl/" target="_blank">Oost West Wonen</a><span class="popular-rental-agents__price">(€25 inschrijfkosten)</span><span class="popular-rental-agents__region">Goeree-Overflakkee</span>
                        </li>
                      </ul>
                    </div>
                  </template>
                </FaqQuestion>

                <FaqQuestion
                  class="mb-4"
                >
                  <template #title>
                    Provincie Limburg
                  </template>
                  <template #default>
                    <div class="faq-question__content">
                      <ul class="popular-rental-agents__list">
                        <li class="popular-rental-agents__rental-agent">
                          <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://www.thuisinlimburg.nl/" target="_blank">Thuis in Limburg</a><span class="popular-rental-agents__price">(gratis)</span>
                        </li>
                        <li class="popular-rental-agents__rental-agent">
                          <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://ikwilhuren.nu/" target="_blank">Ikwilhuren.nu</a><span class="popular-rental-agents__price">(€35 inschrijfkosten)</span>
                        </li>
                        <li class="popular-rental-agents__rental-agent">
                          <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://wonen.thuisbijantares.nl/" target="_blank">Antares</a><span class="popular-rental-agents__price">(gratis)</span>
                          <span class="popular-rental-agents__region">Blerick / Meijel / Tegelen / Steyl / Kessel / Baarlo of Venlo</span>
                        </li>
                        <li class="popular-rental-agents__rental-agent">
                          <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://heemwonen.nl/" target="_blank">HEEMwonen</a><span class="popular-rental-agents__price">(gratis)</span><span class="popular-rental-agents__region">Kerkrade / Landgraaf</span>
                        </li>
                      </ul>
                    </div>
                  </template>
                </FaqQuestion>
              </section>
              <section class="dashboard-card">
                <h2 id="push-meldingen">
                  Instructies om push-meldingen aan te zetten
                </h2>
                <p>
                  Nooit meer een woningmatch missen? Zet push-meldingen aan op je telefoon! Hieronder vind je per device en e-mailprovider een link naar instructies om push-meldingen te activeren:
                </p>
                <ul>
                  <li><a class="text-blue-base hover:cursor-pointer hover:underline" href="https://support.google.com/mail/answer/1075549?hl=nl&amp;co=GENIE.Platform%3DAndroid&amp;oco=0" target="_blank">Instructions voor Gmail op Android</a></li>
                  <li><a class="text-blue-base hover:cursor-pointer hover:underline" href="https://support.google.com/mail/answer/1075549?hl=nl&amp;co=GENIE.Platform%3DiOS&amp;oco=0" target="_blank">Instructies voor Gmail op iPhone</a></li>
                  <li>
                    <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://support.microsoft.com/nl-nl/office/een-o365-e-mailaccount-in-outlook-voor-ios-of-android-instellen-de075b19-b73c-4d8a-841b-459982c7e890" target="_blank">Instructies voor Outlook op Android</a>
                    (klik op "Meldingen van Outlook wijzigen of bewerken")
                  </li>
                  <li><a class="text-blue-base hover:cursor-pointer hover:underline" href="https://support.microsoft.com/nl-nl/office/hoe-kan-ik-pushberichten-en-geluiden-aan-en-uitschakelen-ef8be4f4-85f9-4a90-8c4b-a27f483a0f0a" target="_blank">Instructies voor Outlook op iPhone</a></li>
                </ul>
                <h4 class="mt-4">
                  NIEUW: Pushmeldingen via the Rentbird app
                </h4>
                Ontvang woningmatches via de Rentbird app en reageer nog sneller! Je kunt push-notificaties via de Rentbird app eenvoudig activeren via <a class="text-blue-base hover:cursor-pointer hover:underline" href="/mijn-rentbird/voorkeuren">Mijn Rentbird</a>.
              </section>
              <section class="dashboard-card">
                <h2 id="overige">
                  Overige handige links
                </h2>
                <ul>
                  <li><a class="text-blue-base hover:cursor-pointer hover:underline" href="/mijn-rentbird" target="_blank">Ga naar jouw Rentbird account</a></li>
                  <li><a class="text-blue-base hover:cursor-pointer hover:underline" href="/dashboard/match-history" target="_blank">Bekijk jouw matches van de afgelopen 7 dagen</a></li>
                  <li><a class="text-blue-base hover:cursor-pointer hover:underline" href="https://rentbird.nl/nl/veelgestelde-vragen" target="_blank">Bekijk de veelgestelde vragen</a></li>
                  <li><a class="text-blue-base hover:cursor-pointer hover:underline" href="https://rentbird.nl/nl/contact" target="_blank">Neem contact met ons op</a></li>
                </ul>
              </section>
              <section class="dashboard-card">
                <h2 id="transcript-nl">
                  Transcriptie video
                </h2>
                <p style="white-space: pre-line;">
                  Hey woningzoeker, te gek dat je je hebt aangemeld bij Rentbird!<br>
                  <br>
                  In deze video geven we je 5 tips om met hulp van Rentbird supersnel een woning te vinden.<br>
                  <br>
                  Misschien ben je al een tijd op zoek naar een nieuwe woning en ben je erachter gekomen dat het in de gaten houden van alle woningsites heel erg veel werk is.<br>
                  <br>
                  Of ben je, net als veel andere woningzoekers, behoorlijk gefrustreerd geraakt, omdat de woningen die je online vindt vaak al verhuurd blijken te zijn.<br>
                  <br>
                  Of nog erger, dat je reageert op een woning om vervolgens… niks terug te horen. Gewoon nada, stilte.<br>
                  <br>
                  Het probleem is dat de concurrentie gewoon te groot is.<br>
                  <br>
                  Een woning die nog maar 5 minuten online staat, is vaak al door duizenden mensen bekeken.<br>
                  <br>
                  En aantrekkelijke huizen krijgen binnen 1 dag wel honderden aanmeldingen.<br>
                  <br>
                  Terwijl een verhuurder hooguit 10 of 20 reacties bekijkt.<br>
                  <br>
                  Je moet dus eigenlijk dat en nacht achter je scherm gaan zitten, en continu meerdere verhuursites checken om er op tijd bij te zijn.<br>
                  <br>
                  Bij Rentbird vonden we dat dit slimmer moest kunnen.<br>
                  <br>
                  Veel van het tijdrovende zoeken kan namelijk worden geautomatiseerd.<br>
                  <br>
                  Rentbird gebruikt hier zoekbots voor. Deze intelligente zoekbots houden ruim 1.400 websites dag en nacht in de gaten.<br>
                  <br>
                  Daardoor blijft geen woning onopgemerkt, en vind je ook die pareltjes die andere woningzoekers over het hoofd zien.<br>
                  <br>
                  De zoekbots van Rentbird kunnen 2 dingen ontzettend goed:<br>
                  <br>
                  1) het vinden van vrijwel al het huuraanbod in Nederland, en<br>
                  2) het razendsnel mailen van woningen die matchen bij jouw woonwensen.<br>
                  <br>
                  Wat Rentbird echter niet kan, is het reageren op woningen. Die taak ligt bij jou.<br>
                  <br>
                  En ook daarbij geldt: snelheid is alles.<br>
                  <br>
                  We zien in onze statistieken terug dat gebruikers, die snel en proactief reageren, ruim 3x sneller een owning weten te bemachtigen.<br>
                  <br>
                  Die succesvolle gebruikers openen sneller en vaker de woningmatches die Rentbird stuurt, én reageren direct op een huis die hen bevalt.<br>
                  <br>
                  Met andere woorden: Rentbird werkt dus écht, maar vraagt om ook een proactieve houding van jou.<br>
                  <br>
                  Daarom geven we je 5 tips geven om, met behulp van Rentbird, snel een woning te vinden.<br>
                  <br>
                  Here we go!<br>
                  <br>
                  <strong>Tip 1) Heb een standaard mailtje en alle benodigde documenten klaarstaan</strong><br>
                  Om snel te reageren, moet je goed voorbereid zijn.<br>
                  <br>
                  Typ daarom een nette reactie waarin je kort iets over jezelf vertelt en je interesse kenbaar maakt in de woning.<br>
                  <br>
                  Zorg ook dat je alle vereiste documenten, zoals loonstroken en verhuurdersverklaring, al klaar hebt staan.<br>
                  <br>
                  Onder deze video vind je een kant en klare voorbeeld mail én een lijst met documenten die vaak gevraagd worden door verhuurders.<br>
                  <br>
                  <strong>Tip 2) Maak accounts aan bij populaire verhuurders, zoals woningcorporaties</strong><br>
                  In iedere regio zijn verhuurders actief, veelal woningcorporaties, die vrije sectorwoningen aanbieden tegen relatief lage huurprijzen.<br>
                  <br>
                  Deze partijen werken vaak met een online omgeving waarop je moet inloggen om te kunnen reageren.<br>
                  <br>
                  Om snel op deze woningen te reageren, is het dus zinvol om alvast accounts aan te maken. Wanneer zo’n partij een woning aanbiedt, hoef je op dat moment alleen maar in te loggen om te reageren.<br>
                  <br>
                  Onder deze video vind je per stad de verhuurders waarvoor we je adviseren vooraf een account aan te maken.<br>
                  <br>
                  <strong>Tip 3) Zet push-meldingen op je telefoon aan</strong><br>
                  In plaats van de hele dag je mail te checken, kun je op je telefoon een push-melding instellen voor mails die je krijgt van Rentbird.<br>
                  <br>
                  Je krijgt dan bij iedere woningmatch direct een notificatie.<br>
                  <br>
                  Wel zo handig, en als je eenmaal je woning hebt gevonden zet je de meldingen gewoon weer uit!<br>
                  <br>
                  Onder deze video leggen we je uit hoe je dit voor verschillende telefoons en e-mail aanbieders instelt.<br>
                  <br>
                  <strong>Tip 4) Probeer binnen 1 a 2 minuten te reageren</strong><br>
                  Natuurlijk zijn al deze voorbereidingen alleen nuttig als je ook daadwerkelijk snel reageert.<br>
                  <br>
                  Zie je een woning die je bevalt? Kopieer en plak dat standaard mailtje die je al klaar hebt staan, en stuur je reactie direct!<br>
                  <br>
                  <strong>Tip 5) Heb je bezichtigd en ben je echt enthousiast? Maak dat dan zo snel mogelijk kenbaar!</strong><br>
                  Zeker bij woningen waar veel animo voor is, helpt het om als eerste ja te zeggen.<br>
                  <br>
                  Natuurlijk is het wel belangrijk een overwogen keuze te maken, dus bij twijfel: slaap er nog een nachtje over.<br>
                  <br>
                  Maar, als je jezelf al helemaal in de woning ziet wonen, zorg dan dat je er snel bij bent.<br>
                  <br>
                  <br>
                  Dat waren ze, de 5 tips die jou gaan helpen om goed voorbereid te zijn. Ze vergroten je kans op een<br>
                  woning aanzienlijk, dus ga er vandaag nog mee aan de slag.<br>
                  <br>
                  Heel veel succes met de huizenzoektocht!
                </p>
              </section>
            </div>

            <div v-if="getLocale() === 'en'">
              <section class="dashboard-card">
                <h2>Navigate directly to</h2>
                <ul>
                  <li><a class="text-blue-base hover:cursor-pointer hover:underline" href="#e-mailtemplates">Handy email templates for responding to listings</a> (tip 1)</li>
                  <li><a class="text-blue-base hover:cursor-pointer hover:underline" href="#benodigde-documenten">Required documents for renting</a> (tip 2)</li>
                  <li><a class="text-blue-base hover:cursor-pointer hover:underline" href="#populaire-verhuurders">Popular housing sites per region where you can pre-register</a> (tip 3)</li>
                  <li><a class="text-blue-base hover:cursor-pointer hover:underline" href="#push-meldingen">Instructions to enable push notifications</a> (tip 4)</li>
                  <li><a class="text-blue-base hover:cursor-pointer hover:underline" href="#overige">Other useful links</a> (tip 5)</li>
                </ul>
              </section>
              <section class="dashboard-card">
                <h2 id="e-mailtemplates">
                  Handy email templates for responding to listings
                </h2>
                <FaqQuestion class="mb-4">
                  <template #title>
                    For singles
                  </template>
                  <div class="faq-question__content">
                    <div class="email-template">
                      Dear landlord,<br>
                      <br>
                      I have been looking for rental housing in [CITY/NEIGHBORHOOD/region] for some time now, and I am interested in the property located at [ADDRESS]. I would love to schedule a viewing for this lovely home!<br>
                      <br>
                      My name is [NAME], and I work as a [PROFESSION]. My gross income is €[INCOME] per month. I am single, I [DON'T/DO, IF YES, WHAT KIND OF] have pets and do not smoke.<br>
                      <br>
                      I am available to view the property on [DAYS/TIMES]. When would be a good time for me to come see it?<br>
                      <br>
                      Oh, and I can move in on [IMMEDIATELY/DATE].<br>
                      <br>
                      You can reach me at [PHONE NUMBER] or [EMAIL ADDRESS].<br>
                      <br>
                      Looking forward to hearing from you soon!<br>
                      <br>
                      Best regards,<br>
                      [NAME]<br>
                    </div>
                  </div>
                </FaqQuestion>

                <FaqQuestion class="mb-4">
                  <template #title>
                    Couples and home sharers
                  </template>
                  <div class="faq-question__content">
                    <div class="email-template">
                      Dear landlord,<br>
                      <br>
                      We have been looking for housing in [CITY/NEIGHBORHOOD/region] for some time now, and we are interested in the property located at [ADDRESS]. We would love to schedule a viewing for this lovely home!<br>
                      <br>
                      My name is [NAME], and I work as a [PROFESSION]. My [PARTNER/ROOMMATE] is [NAME], and they work as a [PROFESSION]. Our gross income is €[INCOME] and €[INCOME] per month, respectively. We [DON'T HAVE / HAVE NUMBER OF]
                      children and [DON'T/DO, IF YES, WHAT KIND OF] have pets. We do not smoke.<br>
                      <br>
                      We are available to view the property on [DAYS/TIMES]. When would be a good time for us to come see it?<br>
                      <br>
                      Oh, and we can move in on [IMMEDIATELY/DATE].<br>
                      <br>
                      You can reach me at [PHONE NUMBER] or [EMAIL ADDRESS].<br>
                      <br>
                      Looking forward to hearing from you soon!<br>
                      <br>
                      Best regards,<br>
                      [NAME]
                    </div>
                  </div>
                </FaqQuestion>
              </section>
              <section class="dashboard-card">
                <h2 id="benodigde-documenten">
                  Required documents for renting
                </h2>

                <FaqQuestion class="mb-4">
                  <template #title>
                    If you are self-employed
                  </template>
                  <div class="faq-question__content">
                    <ul>
                      <li>Copy of passport</li>
                      <li>Recently signed employer's statement (and employment contract, if applicable)</li>
                      <li>3 most recent income statements</li>
                      <li>Recent bank statement with visible payment of salary in accordance with payslip</li>
                      <li>Extract from GBA (Municipal Personal Records Database)</li>
                    </ul>
                    <h5>If you already rent a property</h5>
                    <ul>
                      <li>Recently signed landlord statement</li>
                      <li>Bank statements with visible debits from the last 3 months' rents</li>
                    </ul>
                  </div>
                </FaqQuestion>

                <FaqQuestion class="mb-4">
                  <template #title>
                    If you are employed
                  </template>
                  <div class="faq-question__content">
                    <ul>
                      <li>Copy of passport</li>
                      <li>Recent extract from the Chamber of Commerce</li>
                      <li>Profit and loss account for the past 3 years</li>
                      <li>IB60 form last 3 years</li>
                      <li>Extract from GBA (Municipal Personal Records Database)</li>
                    </ul>
                    <h5>If you already rent a property</h5>
                    <ul>
                      <li>Recently signed landlord statement</li>
                      <li>Bank statements with visible debits from the last 3 months' rents</li>
                    </ul>
                  </div>
                </FaqQuestion>
              </section>
              <section class="dashboard-card">
                <h2 id="populaire-verhuurders">
                  Popular housing sites per region where you can pre-register
                </h2>
                <p>Want to increase your chances of securing a home from popular landlords in your region? Pre-register with them now, so you can quickly respond to new listings from these landlords!</p>

                <FaqQuestion class="mb-4">
                  <template #title>
                    Province Noord-Holland
                  </template>
                  <div class="faq-question__content">
                    <ul class="popular-rental-agents__list">
                      <li class="popular-rental-agents__rental-agent">
                        <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://www.ymere.nl/" target="_blank">Ymere</a><span class="popular-rental-agents__price">(free)</span><span class="popular-rental-agents__region">region Amsterdam / Amstelveen / Zaandam</span>
                      </li>
                      <li class="popular-rental-agents__rental-agent">
                        <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://www.eigenhaard.nl/" target="_blank">Eigen Haard</a><span class="popular-rental-agents__price">(free)</span><span class="popular-rental-agents__region">region Amsterdam / Amstelveen / Zaandam</span>
                      </li>
                      <li class="popular-rental-agents__rental-agent">
                        <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://www.vesteda.com/" target="_blank">Vesteda</a><span class="popular-rental-agents__price">(free)</span><span class="popular-rental-agents__region">region Amsterdam / Amstelveen / Zaandam</span>
                      </li>
                      <li class="popular-rental-agents__rental-agent">
                        <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://benopzoek.nl/" target="_blank">Ben Op Zoek</a><span class="popular-rental-agents__price">(free)</span>
                        <span class="popular-rental-agents__region">region Amsterdam / Amstelveen / Zaandam / Hilversum</span>
                      </li>
                      <li class="popular-rental-agents__rental-agent">
                        <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://ikwilhuren.nu/" target="_blank">Ikwilhuren.nu</a><span class="popular-rental-agents__price">(€35 registration fee)</span>
                      </li>
                      <li class="popular-rental-agents__rental-agent">
                        <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://www.vindjeplekbijdekey.nl/" target="_blank">De Key</a><span class="popular-rental-agents__price">(free)</span><span class="popular-rental-agents__region">Amsterdam</span>
                      </li>
                      <li class="popular-rental-agents__rental-agent">
                        <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://www.eigenhaard.nl/" target="_blank">Eigen Haard</a><span class="popular-rental-agents__price">(free)</span><span class="popular-rental-agents__region">region Amsterdam / Amstelveen / Zaandam</span>
                      </li>
                      <a
                        href="
                        https://www.woningnetregioamsterdam.nl/"
                        target="_blank"
                      >
                        Woningnet region Amsterdam
                      </a>
                      <span class="popular-rental-agents__price">(€50 registration fee)</span>
                      <li class="popular-rental-agents__rental-agent">
                        <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://www.rooftrack.nl/" target="_blank">Rooftrack</a><span class="popular-rental-agents__price">(free)</span>
                        <span class="popular-rental-agents__region">region Amsterdam / Amstelveen / Zaandam / Haarlem / Heemstede</span>
                      </li>
                      <li class="popular-rental-agents__rental-agent">
                        <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://www.svnk.nl/" target="_blank">SVNK</a><span class="popular-rental-agents__price">(€25 registration fee)</span><span class="popular-rental-agents__region">region Alkmaar / Noord-Kennemerland</span>
                      </li>
                      <li class="popular-rental-agents__rental-agent">
                        <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://www.mijnwoonservice.nl/" target="_blank">MijnWoonservice</a><span class="popular-rental-agents__price">(€10 registration fee)</span>
                        <span class="popular-rental-agents__region">Haarlem / Zuid-Kennemerland</span>
                      </li>
                      <li class="popular-rental-agents__rental-agent">
                        <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://www.woningnetgooienvechtstreek.nl/" target="_blank">Woningnet Gooi en Vechtstreek</a><span class="popular-rental-agents__price">(€15 registration fee)</span>
                      </li>
                      <li class="popular-rental-agents__rental-agent">
                        <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://woonmatchkopnh.nl/" target="_blank">Woonmatch Kop van Noord Holland</a><span class="popular-rental-agents__price">(€15 registration fee)</span>
                        <span class="popular-rental-agents__region">Den Helder / Schagen / Hollands Kroon</span>
                      </li>
                      <li class="popular-rental-agents__rental-agent">
                        <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://www.wonenindekop.nl/" target="_blank">Woontij</a><span class="popular-rental-agents__price">(€35 registration fee)</span>
                        <span class="popular-rental-agents__region">Den Helder / Julianadorp / Texel</span>
                      </li>
                      <li class="popular-rental-agents__rental-agent">
                        <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://woonmatchwaterland.nl/" target="_blank">Woonmatch Waterland</a><span class="popular-rental-agents__price">(free)</span>
                        <span class="popular-rental-agents__region">Purmerend / Waterland / Edam-Volendam / Beemster</span>
                      </li>
                    </ul>
                  </div>
                </FaqQuestion>

                <FaqQuestion class="mb-4">
                  <template #title>
                    Province Drenthe
                  </template>
                  <div class="faq-question__content">
                    <ul class="popular-rental-agents__list">
                      <li class="popular-rental-agents__rental-agent">
                        <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://www.thuiskompas.nl/" target="_blank">Thuiskompas</a><span class="popular-rental-agents__price">(free)</span>
                      </li>
                      <li class="popular-rental-agents__rental-agent">
                        <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://ikwilhuren.nu/" target="_blank">Ikwilhuren.nu</a><span class="popular-rental-agents__price">(€35 registration fee)</span>
                      </li>
                    </ul>
                  </div>
                </FaqQuestion>

                <FaqQuestion class="mb-4">
                  <template #title>
                    Province Flevoland
                  </template>
                  <div class="faq-question__content">
                    <ul class="popular-rental-agents__list">
                      <li class="popular-rental-agents__rental-agent">
                        <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://www.woningnetalmere.nl/" target="_blank">Woningnet Almere</a><span class="popular-rental-agents__price">(€20 registration fee)</span>
                      </li>
                      <li class="popular-rental-agents__rental-agent">
                        <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://ikwilhuren.nu/" target="_blank">Ikwilhuren.nu</a><span class="popular-rental-agents__price">(€35 registration fee)</span>
                      </li>
                      <li class="popular-rental-agents__rental-agent">
                        <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://benopzoek.nl/" target="_blank">Ben Op Zoek</a><span class="popular-rental-agents__price">(free)</span><span class="popular-rental-agents__region">Almere</span>
                      </li>
                    </ul>
                  </div>
                </FaqQuestion>

                <FaqQuestion class="mb-4">
                  <template #title>
                    Province Friesland
                  </template>
                  <div class="faq-question__content">
                    <ul class="popular-rental-agents__list">
                      <li class="popular-rental-agents__rental-agent">
                        <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://www.frieslandhuurt.nl/" target="_blank">Friesland Huurt</a><span class="popular-rental-agents__price">(free)</span>
                      </li>
                      <li class="popular-rental-agents__rental-agent">
                        <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://woonmatchwestfriesland.nl/" target="_blank">Woonmatch West Friesland</a><span class="popular-rental-agents__price">(free)</span>
                      </li>
                      <li class="popular-rental-agents__rental-agent">
                        <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://www.debouwvereniging.nl/" target="_blank">De Bouwvereniging</a><span class="popular-rental-agents__price">(€15 registration fee)</span><span class="popular-rental-agents__region">Harlingen</span>
                      </li>
                      <li class="popular-rental-agents__rental-agent">
                        <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://ikwilhuren.nu/" target="_blank">Ikwilhuren.nu</a><span class="popular-rental-agents__price">(€35 registration fee)</span>
                      </li>
                    </ul>
                  </div>
                </FaqQuestion>

                <FaqQuestion class="mb-4">
                  <template #title>
                    Province Gelderland
                  </template>
                  <div class="faq-question__content">
                    <ul class="popular-rental-agents__list">
                      <li class="popular-rental-agents__rental-agent">
                        <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://www.entree.nu/" target="_blank">Entree</a><span class="popular-rental-agents__price">(free)</span><span class="popular-rental-agents__region">region Arnhem / Nijmegen</span>
                      </li>
                      <li class="popular-rental-agents__rental-agent">
                        <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://www.woonkeus-stedendriehoek.nl/" target="_blank">Woonkeus Stedendriehoek</a><span class="popular-rental-agents__price">(€30 registration fee)</span>
                        <span class="popular-rental-agents__region">region Apeldoorn / Deventer / Zutphen</span>
                      </li>
                      <li class="popular-rental-agents__rental-agent">
                        <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://www.thuisindeachterhoek.nl/" target="_blank">Thuis in de Achterhoek</a><span class="popular-rental-agents__price">(free)</span><span class="popular-rental-agents__region">region Achterhoek</span>
                      </li>
                      <li class="popular-rental-agents__rental-agent">
                        <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://ikwilhuren.nu/" target="_blank">Ikwilhuren.nu</a><span class="popular-rental-agents__price">(€35 registration fee)</span>
                      </li>
                      <li class="popular-rental-agents__rental-agent">
                        <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://www.hurennoordveluwe.nl/" target="_blank">Huren Noord Veluwe</a><span class="popular-rental-agents__price">(€15 registration fee)</span>
                      </li>
                      <li class="popular-rental-agents__rental-agent">
                        <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://www.mijnwoningzoeken.nl/" target="_blank">Mooiland</a><span class="popular-rental-agents__price">(free)</span><span class="popular-rental-agents__region">region Arnhem / Nijmegen</span>
                      </li>
                    </ul>
                  </div>
                </FaqQuestion>

                <FaqQuestion class="mb-4">
                  <template #title>
                    Province Groningen
                  </template>
                  <div class="faq-question__content">
                    <ul class="popular-rental-agents__list">
                      <li class="popular-rental-agents__rental-agent">
                        <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://www.rooftrack.nl/" target="_blank">Rooftrack</a><span class="popular-rental-agents__price">(free)</span>
                      </li>
                      <li class="popular-rental-agents__rental-agent">
                        <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://woningzoeken.acantus.nl/" target="_blank">Acantus</a><span class="popular-rental-agents__price">(€15 registration fee)</span>
                      </li>
                      <li class="popular-rental-agents__rental-agent">
                        <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://ikwilhuren.nu/" target="_blank">Ikwilhuren.nu</a><span class="popular-rental-agents__price">(€35 registration fee)</span>
                      </li>
                    </ul>
                  </div>
                </FaqQuestion>

                <FaqQuestion class="mb-4">
                  <template #title>
                    Province Noord-Brabant
                  </template>
                  <div class="faq-question__content">
                    <ul class="popular-rental-agents__list">
                      <li class="popular-rental-agents__rental-agent">
                        <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://www.woonserviceregionaal.nl/" target="_blank">WoonService Regionaal</a><span class="popular-rental-agents__price">(€10 registration fee)</span>
                        <span class="popular-rental-agents__region">Noordoost-Braband</span>
                      </li>
                      <li class="popular-rental-agents__rental-agent">
                        <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://www.zuidwestwonen.nl/" target="_blank">Zuidwestwonen</a><span class="popular-rental-agents__price">(€17,50 registration fee)</span><span class="popular-rental-agents__region">West-Brabant</span>
                      </li>
                      <li class="popular-rental-agents__rental-agent">
                        <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://www.klikvoorwonen.nl/" target="_blank">Klik voor Wonen</a><span class="popular-rental-agents__price">(€17,50 registration fee)</span><span class="popular-rental-agents__region">West-Brabant</span>
                      </li>
                      <li class="popular-rental-agents__rental-agent">
                        <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://ikwilhuren.nu/" target="_blank">Ikwilhuren.nu</a><span class="popular-rental-agents__price">(€35 registration fee)</span>
                      </li>
                      <li class="popular-rental-agents__rental-agent">
                        <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://www.wooniezie.nl/" target="_blank">Wooniezie</a><span class="popular-rental-agents__price">(free)</span><span class="popular-rental-agents__region">region Helmond / Eindhoven</span>
                      </li>
                      <li class="popular-rental-agents__rental-agent">
                        <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://www.onshuiz.nl/" target="_blank">Ons Huiz</a><span class="popular-rental-agents__price">(free)</span><span class="popular-rental-agents__region">region Uden, Meierijstad en Sint-Michielsgestel</span>
                      </li>
                      <li class="popular-rental-agents__rental-agent">
                        <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://www.woninginzicht.nl/" target="_blank">Woninginzicht</a><span class="popular-rental-agents__price">(€8 registration fee)</span><span class="popular-rental-agents__region">Midden-Brabant</span>
                      </li>
                      <li class="popular-rental-agents__rental-agent">
                        <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://www.mijnwoningzoeken.nl/" target="_blank">Mooiland</a><span class="popular-rental-agents__price">(free)</span><span class="popular-rental-agents__region">Noordoost-Braband</span>
                      </li>
                    </ul>
                  </div>
                </FaqQuestion>

                <FaqQuestion class="mb-4">
                  <template #title>
                    Province Overijssel
                  </template>
                  <div class="faq-question__content">
                    <ul class="popular-rental-agents__list">
                      <li class="popular-rental-agents__rental-agent">
                        <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://www.woonkeus-stedendriehoek.nl/" target="_blank">Woonkeus Stedendriehoek</a><span class="popular-rental-agents__price">(€30 registration fee)</span>
                        <span class="popular-rental-agents__region">region Apeldoorn / Deventer / Zutphen</span>
                      </li>
                      <li class="popular-rental-agents__rental-agent">
                        <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://www.dewoonplaats.nl/" target="_blank">De Woonplaats</a><span class="popular-rental-agents__price">(free)</span>
                        <span class="popular-rental-agents__region">Enschede / Aalten / Oost Gelre / Winterswijk</span>
                      </li>
                      <li class="popular-rental-agents__rental-agent">
                        <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://www.dewoningzoeker.nl/" target="_blank">De Woningzoeker</a><span class="popular-rental-agents__price">(€8 registration fee)</span>
                        <span class="popular-rental-agents__region">region Zwolle / Kampen / Steenwijkerland / Zwartewaterland</span>
                      </li>
                      <li class="popular-rental-agents__rental-agent">
                        <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://ikwilhuren.nu/" target="_blank">Ikwilhuren.nu</a><span class="popular-rental-agents__price">(€35 registration fee)</span>
                      </li>
                      <li class="popular-rental-agents__rental-agent">
                        <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://www.woningnethengeloborne.nl/" target="_blank">Woningnet Hengelo Borne</a><span class="popular-rental-agents__price">(€15 registration fee)</span>
                      </li>
                      <li class="popular-rental-agents__rental-agent">
                        <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://www.thuistreffervechtdal.nl/" target="_blank">Thuistreffer Vechtdal</a><span class="popular-rental-agents__price">(free)</span>
                      </li>
                      <li class="popular-rental-agents__rental-agent">
                        <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://www.woonburoalmelo.nl/" target="_blank">Woonburo Almelo</a><span class="popular-rental-agents__price">(€25 registration fee)</span>
                      </li>
                      <li class="popular-rental-agents__rental-agent">
                        <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://www.mijnwoningzoeken.nl/" target="_blank">Mooiland</a><span class="popular-rental-agents__price">(free)</span><span class="popular-rental-agents__region">region Apeldoorn</span>
                      </li>
                      <li class="popular-rental-agents__rental-agent">
                        <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://woningzoeken.sallandwonen.nl/" target="_blank">SallandWonen</a><span class="popular-rental-agents__price">(€10 registration fee)</span>
                        <span class="popular-rental-agents__region">region Salland</span>
                      </li>
                      <li class="popular-rental-agents__rental-agent">
                        <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://woningzoeken.reggewoon.nl/" target="_blank">Reggewoon</a><span class="popular-rental-agents__price">(€15 registration fee)</span>
                        <span class="popular-rental-agents__region">Wierden / Hellendoorn</span>
                      </li>
                    </ul>
                  </div>
                </FaqQuestion>

                <FaqQuestion class="mb-4">
                  <template #title>
                    Province Utrecht
                  </template>
                  <div class="faq-question__content">
                    <ul class="popular-rental-agents__list">
                      <li class="popular-rental-agents__rental-agent">
                        <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://www.woningnetregioutrecht.nl/" target="_blank">Woningnet region Utrecht</a><span class="popular-rental-agents__price">(€20 registration fee)</span>
                      </li>
                      <li class="popular-rental-agents__rental-agent">
                        <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://www.woningnetgooienvechtstreek.nl/" target="_blank">Woningnet Gooi en Vechtstreek</a><span class="popular-rental-agents__price">(€15 registration fee)</span>
                      </li>
                      <li class="popular-rental-agents__rental-agent">
                        <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://www.woningneteemvallei.nl/" target="_blank">Woningnet regio Eemvallei</a><span class="popular-rental-agents__price">(€17,50 registration fee)</span>
                      </li>
                      <li class="popular-rental-agents__rental-agent">
                        <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://ikwilhuren.nu/" target="_blank">Ikwilhuren.nu</a><span class="popular-rental-agents__price">(€35 registration fee)</span>
                      </li>
                      <li class="popular-rental-agents__rental-agent">
                        <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://www.rooftrack.nl/" target="_blank">Rooftrack</a><span class="popular-rental-agents__price">(free)</span><span class="popular-rental-agents__region">region Utrecht</span>
                      </li>
                      <li class="popular-rental-agents__rental-agent">
                        <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://benopzoek.nl/" target="_blank">Ben Op Zoek</a><span class="popular-rental-agents__price">(free)</span><span class="popular-rental-agents__region">region Amersfoort</span>
                      </li>
                      <li class="popular-rental-agents__rental-agent">
                        <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://www.huiswaarts.nu/" target="_blank">Huiswaarts.nu</a><span class="popular-rental-agents__price">(€25 registration fee)</span>
                        <span class="popular-rental-agents__region">region Veenendaal / Rhenen / Ede</span>
                      </li>
                    </ul>
                  </div>
                </FaqQuestion>

                <FaqQuestion class="mb-4">
                  <template #title>
                    Province Zeeland
                  </template>
                  <div class="faq-question__content">
                    <ul class="popular-rental-agents__list">
                      <li class="popular-rental-agents__rental-agent">
                        <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://www.zuidwestwonen.nl/" target="_blank">Zuidwestwonen</a><span class="popular-rental-agents__price">(€17,50 registration fee)</span>
                      </li>
                      <li class="popular-rental-agents__rental-agent">
                        <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://ikwilhuren.nu/" target="_blank">Ikwilhuren.nu</a><span class="popular-rental-agents__price">(€35 registration fee)</span>
                      </li>
                    </ul>
                  </div>
                </FaqQuestion>

                <FaqQuestion class="mb-4">
                  <template #title>
                    Province Zuid-Holland
                  </template>
                  <div class="faq-question__content">
                    <ul class="popular-rental-agents__list">
                      <li class="popular-rental-agents__rental-agent">
                        <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://www.hureninhollandrijnland.nl/" target="_blank">Huren in Holland Rijnland</a><span class="popular-rental-agents__price">(€7,50 registration fee)</span>
                        <span class="popular-rental-agents__region">region Leiden / Bollenstreek / Rijnland</span>
                      </li>
                      <li class="popular-rental-agents__rental-agent">
                        <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://www.woonnetrijnmond.nl/" target="_blank">Woonnet Rijnmond</a><span class="popular-rental-agents__price">(€10 registration fee)</span>
                        <span class="popular-rental-agents__region">region Rotterdam</span>
                      </li>
                      <li class="popular-rental-agents__rental-agent">
                        <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://ikwilhuren.nu/" target="_blank">Ikwilhuren.nu</a><span class="popular-rental-agents__price">(€35 registration fee)</span>
                      </li>
                      <li class="popular-rental-agents__rental-agent">
                        <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://www.woningnetregiomiddenholland.nl/" target="_blank">Woingnet regio Midden-Holland</a><span class="popular-rental-agents__price">(€23 registration fee)</span>
                      </li>
                      <li class="popular-rental-agents__rental-agent">
                        <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://www.woonnet-haaglanden.nl/" target="_blank">Woonnet Haaglanden</a><span class="popular-rental-agents__price">(€12,50 registration fee)</span>
                        <span class="popular-rental-agents__region">region Den Haag</span>
                      </li>
                      <li class="popular-rental-agents__rental-agent">
                        <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://www.woonstadrotterdam.nl/" target="_blank">Woonstad Rotterdam</a><span class="popular-rental-agents__price">(free)</span>
                      </li>
                      <li class="popular-rental-agents__rental-agent">
                        <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://www.wonenindekrimpenerwaard.nl/" target="_blank">Wonen in de Krimpenerwaard</a><span class="popular-rental-agents__price">(free)</span>
                      </li>
                      <li class="popular-rental-agents__rental-agent">
                        <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://www.thuisbijhwwonen.nl/" target="_blank">HW Wonen</a><span class="popular-rental-agents__price">(free)</span><span class="popular-rental-agents__region">Hoekse Waard</span>
                      </li>
                      <li class="popular-rental-agents__rental-agent">
                        <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://www.oostwestwonen.nl/" target="_blank">Oost West Wonen</a><span class="popular-rental-agents__price">(€25 registration fee)</span><span class="popular-rental-agents__region">Goeree-Overflakkee</span>
                      </li>
                    </ul>
                  </div>
                </FaqQuestion>

                <FaqQuestion class="mb-4">
                  <template #title>
                    Province Limburg
                  </template>
                  <div class="faq-question__content">
                    <ul class="popular-rental-agents__list">
                      <li class="popular-rental-agents__rental-agent">
                        <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://www.thuisinlimburg.nl/" target="_blank">Thuis in Limburg</a><span class="popular-rental-agents__price">(free)</span>
                      </li>
                      <li class="popular-rental-agents__rental-agent">
                        <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://ikwilhuren.nu/" target="_blank">Ikwilhuren.nu</a><span class="popular-rental-agents__price">(€35 registration fee)</span>
                      </li>
                      <li class="popular-rental-agents__rental-agent">
                        <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://wonen.thuisbijantares.nl/" target="_blank">Antares</a><span class="popular-rental-agents__price">(free)</span>
                        <span class="popular-rental-agents__region">Blerick / Meijel / Tegelen / Steyl / Kessel / Baarlo of Venlo</span>
                      </li>
                      <li class="popular-rental-agents__rental-agent">
                        <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://heemwonen.nl/" target="_blank">HEEMwonen</a><span class="popular-rental-agents__price">(free)</span><span class="popular-rental-agents__region">Kerkrade / Landgraaf</span>
                      </li>
                    </ul>
                  </div>
                </FaqQuestion>
              </section>
              <section class="dashboard-card">
                <h2 id="push-meldingen">
                  Instructions to enable push notifications
                </h2>
                <p>
                  Never miss a housing match again! Enable push notifications on your phone by following the links below for your device and email provider:
                </p>
                <ul>
                  <li><a class="text-blue-base hover:cursor-pointer hover:underline" href="https://support.google.com/mail/answer/1075549?hl=en&amp;co=GENIE.Platform%3DAndroid" target="_blank">Instructions for Gmail on Android</a></li>
                  <li><a class="text-blue-base hover:cursor-pointer hover:underline" href="https://support.google.com/mail/answer/1075549?hl=en&amp;co=GENIE.Platform%3DiOS" target="_blank">Instructions voor Gmail on iPone</a></li>
                  <li>
                    <a class="text-blue-base hover:cursor-pointer hover:underline" href="https://support.microsoft.com/en-us/office/optimize-the-outlook-mobile-app-for-your-ios-or-android-phone-de075b19-b73c-4d8a-841b-459982c7e890" target="_blank">Instructions for Outlook on Android</a>
                    (click on "Change or update Outlook notifications")
                  </li>
                  <li><a class="text-blue-base hover:cursor-pointer hover:underline" href="https://support.microsoft.com/en-us/office/how-can-i-turn-push-notifications-and-sounds-on-or-off-ef8be4f4-85f9-4a90-8c4b-a27f483a0f0a" target="_blank">Instructions for Outlook on iPhone</a></li>
                </ul>
                <h4 class="mt-4">
                  NEW: Push notifications via the Rentbird app
                </h4>
                Receive housing matches via the Rentbird app and respond even faster! You can easily activate push notifications via <a class="text-blue-base hover:cursor-pointer hover:underline" href="/my-rentbird/preferences">My Rentbird.</a>
              </section>
              <section class="dashboard-card">
                <h2 id="overige">
                  Other useful links
                </h2>
                <ul>
                  <li><a class="text-blue-base hover:cursor-pointer hover:underline" href="/mijn-rentbird" target="_blank">Go to your Rentbird account</a></li>
                  <li><a class="text-blue-base hover:cursor-pointer hover:underline" href="/dashboard/match-history" target="_blank">View your matches from the past 7 days</a></li>
                  <li><a class="text-blue-base hover:cursor-pointer hover:underline" href="https://rentbird.nl/en/faq" target="_blank">View frequently asked questions</a></li>
                  <li><a class="text-blue-base hover:cursor-pointer hover:underline" href="https://rentbird.nl/en/support" target="_blank">Contact us here</a></li>
                </ul>
              </section>
              <section class="dashboard-card">
                <h2 id="transcript-en">
                  Transcription video
                </h2>
                <p style="white-space: pre-line;">
                  Hey home hunter, you signed up for Rentbird, that’s awesome! <br>
                  <br>
                  In this video we’ll give you 5 tips to find a new home super quickly using Rentbird!<br>
                  <br>
                  Maybe you’ve been looking for a new place for a while and you’ve noticed that it takes an insane amount of time to keep track of all of those rental websites.<br>
                  <br>
                  Or maybe, just like many other home hunters, you’ve \frustrated because the houses you see online often turn out to already be reserved.<br>
                  <br>
                  Or worse, you replied to a listing only to hear… nothing. Not even a rejection. Just… silence.<br>
                  <br>
                  The problem is, there’s just too much competition.<br>
                  <br>
                  A place that has been listed for only 5 minutes has often already been seen by thousands of people.<br>
                  <br>
                  And the good ones receive hundreds of applicants within the first day.<br>
                  <br>
                  All while a landlord only checks about 10 to 20 applicants.<br>
                  <br>
                  So you’d actually have to stare at your screen day and night, and consistently check multiple rental websites to be early.<br>
                  <br>
                  At Rentbird we figured this could be done in a smarter way.<br>
                  <br>
                  Because lot’s of the time consuming searches can be automated.<br>
                  <br>
                  Rentbird uses search bots to do this. These intelligent bots keep an eye on over 1400 websites, day and night.<br>
                  <br>
                  This way, no property will go unnoticed and you’ll even discover those hidden gems that other home hunters miss.<br>
                  <br>
                  <br>
                  The Rentbird search bots are really good at 2 things.<br>
                  <br>
                  1) Finding almost all rental places in the Netherlands, and <br>
                  2) Sending you the listings that match your wishes, super fast.<br>
                  <br>
                  What Rentbird can’t do, however, is reply to these listings. That is your task.<br>
                  <br>
                  And you gotta keep in mind: speed is everything.<br>
                  <br>
                  Our analytics show that users who reply quickly and proactively are more than 3 times more likely to get a new place.<br>
                  <br>
                  Those successful users open their Rentbird matches quicker and more often. And they also reply instantaneously to the listings they like.<br>
                  <br>
                  In other words: Rentbird really works, but it does require you to be proactive as well.<br>
                  <br>
                  That’s why we’re giving you 5 tips to find a new place to live, super fast, using Rentbird.<br>
                  <br>
                  Here we go!<br>
                  <br>
                  <strong>Tip 1) Draft an email template and get all of your documents ready.</strong><br>
                  <br>
                  In order to reply quickly, you need to be prepared.<br>
                  <br>
                  Write down a neat reply email in which you tell a little bit about yourself and show you are interested in this rental property.<br>
                  <br>
                  You also gotta make sure you’ve got all required documents ready to go. Such as your salary and a landlord notice.<br>
                  <br>
                  Underneath this video you’ll find a ready to go email template and a list of documents that landlords often want to see.<br>
                  <br>
                  <strong>Tip 2) Create accounts on popular rental websites, such as housing associations</strong><br>
                  In every area there are people that rent out places, usually housing associations, who offer private rental sector houses for relatively low rental prices.<br>
                  <br>
                  These parties often work with a website that requires you to log in to be able to reply.<br>
                  <br>
                  To be able to reply quickly it’s useful to already make these accounts. Because when one of these parties offers something new, all you gotta do is log in to reply.<br>
                  <br>
                  Under this video you’ll find the housing associations per region we recommend you already make an account for.<br>
                  <br>
                  <strong>Tip 3) Turn on notifications on your phone</strong><br>
                  Instead of checking your email all day long, you can enable notifications specifically for the emails that Rentbird sends you.<br>
                  <br>
                  This way you’ll get a notification whenever there’s a match.<br>
                  <br>
                  This is really useful, and after you find your new place you can simply turn these notifications off!<br>
                  <br>
                  Under this video we’ll explain how to enable these notifications on several phones and email providers.<br>
                  <br>
                  <br>
                  <strong>Tip 4) Try to reply within 1 to 2 minutes</strong><br>
                  Obviously, all of these preparations are only useful if you actually reply quickly.<br>
                  <br>
                  Do you see a house you like? Copy and paste your email template and send it to them right away!<br>
                  <br>
                  <strong>Tip 5) Have you checked out a place and you like it? Let them know right away!</strong><br>
                  It helps being the first to say you want the place, especially if many others also want the same house.<br>
                  <br>
                  But of course, it is important to make the right decision, so if you’re in doubt, sleep on it for a night.<br>
                  <br>
                  But if you can totally picture yourself living there, be sure to be fast.<br>
                  <br>
                  <br>
                  Well here you have them, the 5 tips that’ll help you to be prepared. These tips significantly increase your odds of getting the rental house you like, so get on it today.<br>
                  <br>
                  We wish you lots of luck on your quest to find your perfect place!
                </p>
              </section>
            </div>
          </div>
        </template>
      </BlogPostCard>
    </div>
  </MyRentbirdLayout>
</template>
<style>
.MyRentbird-OnboardingTipsPage {
  background: rgb(234, 234, 235);
}

.MyRentbird-OnboardingTipsPage h2 {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 4px;

  @media screen and (min-width: 997px) {
    font-size: 30px;
    margin-bottom: 8px;
  }
}

.MyRentbird-OnboardingTipsPage h5 {
  font-size: 16px;
  margin-bottom: 8px;
  margin-top: 12px;

  @media screen and (min-width: 997px) {
    margin-top: 12px;
    font-size: 20px;
    margin-bottom: 8px;
  }
}

.MyRentbird-OnboardingTipsPage p {
  margin-bottom: 12px;
}

.MyRentbird-OnboardingTipsPage p, .MyRentbird-OnboardingTipsPage li {
  font-size: 16px;

  @media screen and (min-width: 997px) {
    font-size: 18px;
  }
}

.MyRentbird-OnboardingTipsPage li {
  margin-bottom: 4px;
}

.MyRentbird-OnboardingTipsPage .popular-rental-agents__price {
  padding-left: 6px;
}

.MyRentbird-OnboardingTipsPage .popular-rental-agents__region {
  padding-left: 6px;
}

.MyRentbird-OnboardingTipsPage .dashboard-card {
  margin-bottom: 24px;

  @media screen and (min-width: 997px) {
    margin-bottom: 40px;
  }
}

</style>
