<script setup>
import RentbirdLogo from '@js/Components/Logos/RentbirdLogo/RentbirdLogo.vue'
import TrustpilotRating from '@js/Components/Trustpilot/TrustpilotRating.vue'
import TrustpilotSmall from '@js/Components/Trustpilot/TrustpilotSmall.vue'
import { useIsMobile } from '@js/App/Utils/BrowserDetection'
import Container from '@js/Components/Container/Container.vue'

const { isMobile } = useIsMobile()

</script>
<template>
  <div class="bg-purple-700">
    <Container>
      <div class="py-6 lg:py-8 flex justify-between items-center">
        <RentbirdLogo :size="isMobile ? 'xs' : 'sm'" />
        <TrustpilotRating v-if="isMobile" :black="false" />
        <TrustpilotSmall
          v-else
          :black="false"
          class="relative top-[4px]"
        />
      </div>
    </Container>
  </div>
</template>
