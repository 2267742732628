<script setup>
import { useMainStore } from '@js/App/Stores/MainStore'
import { computed, watch } from 'vue'
import {
  CREATE_RENTBIRD_PLUS_ACCOUNT_MODAL_ID,
  CREATE_SEARCH_PROFILE_AND_ACCOUNT_MODAL_ID,
  ONBOARDING_MODAL,
  VIDEO_MODAL
} from '@js/App/Plugins/Modals'
import BasicModal from '@js/Components/Modals/BasicModal.vue'
import { storeToRefs } from 'pinia'
import OnboardingWizard from '@js/Components/MyRentbird/Onboarding/OnboardingWizard/OnboardingWizard.vue'
import CreateSearchProfileAndAccountWizard
  from '@js/Components/SearchProfileWizards/SearchProfileWizard/CreateSearchProfileAndAccountWizard.vue'
import ModalVideo from '@js/Components/Modals/ModalVideo/ModalVideo.vue'
import RentbirdPlusWizard from '@js/Components/SearchProfileWizards/RentbirdPlusWizard/RentbirdPlusWizard.vue'

const mainStore = useMainStore()

const showCreateSearchProfileAndAccountModal = computed(() =>
  mainStore.openModals?.includes(CREATE_SEARCH_PROFILE_AND_ACCOUNT_MODAL_ID)
)

const showCreateRentbirdPlusAccountModal = computed(() =>
  mainStore.openModals?.includes(CREATE_RENTBIRD_PLUS_ACCOUNT_MODAL_ID)
)

const showOnboardingWizardModal = computed(() =>
  mainStore.openModals?.includes(ONBOARDING_MODAL)
)

const showVideoModal = computed(() =>
  mainStore.openModals?.includes(VIDEO_MODAL)
)

const isAnyModalOpen = computed(() =>
  mainStore.openModals?.length && mainStore.openModals.length > 0
)

const videoToShowInModal = computed(() => mainStore.videoToShowInModal)

const { fadingOutModals } = storeToRefs(mainStore)

watch(isAnyModalOpen, () => {
  const bodyEl = document.querySelector('body')
  if (isAnyModalOpen.value) {
    bodyEl.classList.remove('overflow-auto')
    bodyEl.classList.add('overflow-hidden')
  } else {
    bodyEl.classList.add('overflow-auto')
    bodyEl.classList.remove('overflow-hidden')
  }
})
</script>
<template>
  <BasicModal
    v-if="showCreateSearchProfileAndAccountModal"
    :show-top-bar="false"
    :padding="false"
    :just-got-closed="fadingOutModals.includes(CREATE_SEARCH_PROFILE_AND_ACCOUNT_MODAL_ID)"
    @close="mainStore.closeModal(CREATE_SEARCH_PROFILE_AND_ACCOUNT_MODAL_ID)"
  >
    <CreateSearchProfileAndAccountWizard />
  </BasicModal>

  <BasicModal
    v-if="showCreateRentbirdPlusAccountModal"
    :show-top-bar="false"
    :padding="false"
    :just-got-closed="fadingOutModals.includes(CREATE_RENTBIRD_PLUS_ACCOUNT_MODAL_ID)"
    @close="mainStore.closeModal(CREATE_RENTBIRD_PLUS_ACCOUNT_MODAL_ID)"
  >
    <RentbirdPlusWizard />
  </BasicModal>

  <BasicModal
    v-if="showOnboardingWizardModal"
    :show-top-bar="false"
    :padding="false"
    :just-got-closed="fadingOutModals.includes(ONBOARDING_MODAL)"
    @close="mainStore.closeModal(ONBOARDING_MODAL)"
  >
    <OnboardingWizard />
  </BasicModal>

  <ModalVideo
    v-if="showVideoModal"
    :video="videoToShowInModal"
    @close="mainStore.closeModal(VIDEO_MODAL)"
  />
</template>
